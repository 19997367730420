import { FC, useContext } from 'react';

import { DownloadPanelFunc, DownloadSpreadsheetFunc } from 'actions/exportActions';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { ExportOptions } from 'components/ExportOptions/ExportOptions';
import { EmbedButton, EmbedPopover } from 'components/embed';
import { ExportConfig } from 'constants/types';
import { useDownload } from 'utils/exportUtils';

interface Props {
  dataPanelId: string;
  disabled?: boolean;
  exportConfig?: ExportConfig;
  enableDrilldownModal?: boolean;
  onDownloadPanelSpreadsheet?: DownloadSpreadsheetFunc;
  onDownloadPanelImage?: DownloadPanelFunc;
}

export const ChartMenu: FC<Props> = ({
  dataPanelId,
  disabled,
  enableDrilldownModal,
  exportConfig,
  onDownloadPanelSpreadsheet,
  onDownloadPanelImage,
}) => {
  const context = useContext(DashboardLayoutContext);

  // If export props is not passed then exporting should not be allowed at all

  const { windowState, downloadInfo } = useDownload(
    dataPanelId,
    !exportConfig || exportConfig.disableDownloadExport,
  );

  if (!enableDrilldownModal && !exportConfig) return null;

  return (
    <EmbedPopover
      align="end"
      portalContainerId={context.dashboardLayoutTagId}
      trigger={
        <EmbedButton
          data-testid={`chart-menu-${dataPanelId}`}
          disabled={disabled}
          icon="ellipsis-vertical"
          variant="tertiary"
        />
      }
      width="medium">
      <ExportOptions
        dataPanelId={dataPanelId}
        downloadInfo={downloadInfo}
        enableDrilldownModal={enableDrilldownModal}
        exportConfig={exportConfig}
        onDownloadPanelImage={onDownloadPanelImage}
        onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
        windowState={windowState}
      />
    </EmbedPopover>
  );
};
