import { FC, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { IconName } from 'components/ds/Icon';
import { EmbedButton } from 'components/embed';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { DRAFT_REPORT_ID } from 'pages/ReportBuilder/constants';
import * as RD from 'remotedata';
import {
  openModalType,
  toggleExportDownloaded,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportModalType } from 'reportBuilderContent/reducers/types';

import * as constants from './constants';

type Props = { exportDisabled: boolean };

export const ExportButton: FC<Props> = ({ exportDisabled }) => {
  const dispatch = useDispatch();
  const { exportUrl, isExportDownloaded, selectedReport, enableEmailExport, isPreview } =
    useSelector(
      (state: ReportBuilderReduxState) => ({
        exportUrl: state.reportEditing.exportUrl,
        isExportDownloaded: state.reportEditing.isExportDownloaded,
        selectedReport: state.reportEditing.selectedReport,
        enableEmailExport: state.embeddedReportBuilder.team?.entitlements.enable_email_exports,
        isPreview: state.embeddedReportBuilder.isPreview,
      }),
      shallowEqual,
    );

  useEffect(() => {
    if (!RD.isSuccess(exportUrl) || isExportDownloaded) return;
    window.open(exportUrl.data);
    dispatch(toggleExportDownloaded());
  }, [dispatch, exportUrl, isExportDownloaded]);

  const isLoading = RD.isLoading(exportUrl);
  const isDraftReport = selectedReport?.id === DRAFT_REPORT_ID;

  const menuOptions: DropdownOption[] = useMemo(() => {
    const options: DropdownOption[] = [
      {
        disabled: exportDisabled,
        icon: 'paper-plane' as IconName,
        name: 'Export now',
        onClick: () => dispatch(openModalType({ type: ReportModalType.EXPORT_NOW })),
      },
    ];

    if (enableEmailExport) {
      options.push({
        disabled: exportDisabled,
        icon: 'clock' as IconName,
        name: 'Schedule export',
        onClick: () => dispatch(openModalType({ type: ReportModalType.SCHEDULE_EXPORT })),
      });
    }

    return options;
  }, [dispatch, exportDisabled, enableEmailExport]);

  const tooltipText = isPreview ? constants.AVAILABLE_IN_EMBED_TEXT : constants.SAVE_REPORT_TEXT;

  return (
    <EmbeddedDropdownMenu disabled={isLoading || isDraftReport} menuOptions={menuOptions}>
      <EmbedButton
        data-testid="open-export-dropdown"
        disabled={isDraftReport || isPreview}
        icon="file-export"
        loading={isLoading}
        tooltipProps={{ text: tooltipText }}
        variant="primary">
        Export
      </EmbedButton>
    </EmbeddedDropdownMenu>
  );
};
