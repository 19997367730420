import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  AreaChartView,
  CustomerReportAgg,
  CustomerReportGroupBy,
  CustomerReportView,
} from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { OPERATION_TYPES } from 'constants/types';
import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import {
  cloneRowsIfDate,
  customerReportToLineChartInstructions,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import LineChart from 'pages/dashboardPage/charts/lineChart';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { getCurrentTheme } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { getCurrentColorTracker } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';
import { DatasetColumn } from 'types/datasets';

type Props = {
  view: CustomerReportView;
  groupBys?: CustomerReportGroupBy[];
  columnGroupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  reportData: ReportData;
  schema: DatasetColumn[];
  columnConfigs?: ReportBuilderColConfigs;
  visualization: OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 | OPERATION_TYPES.VISUALIZE_AREA_CHART_V2;
  onSelect?: (chartInfo: ChartMenuInfo | null) => void;
};

export const ReportLineChart: FC<Props> = ({
  view,
  aggregations,
  reportData: { isLoading, rows },
  groupBys,
  columnGroupBys,
  schema,
  columnConfigs,
  visualization,
  onSelect,
}) => {
  const { variables, globalStyleConfig, colorCategoryTracker } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: getCurrentTheme(state.embeddedReportBuilder),
      colorCategoryTracker: getCurrentColorTracker(state.reportEditing),
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () =>
      customerReportToLineChartInstructions(aggregations, groupBys, columnGroupBys, columnConfigs),
    [aggregations, groupBys, columnGroupBys, columnConfigs],
  );

  // LineChart mutates previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const previewData = useMemo(
    () => cloneRowsIfDate([...(groupBys ?? []), ...(columnGroupBys ?? [])], rows),
    [groupBys, columnGroupBys, rows],
  );

  const missingText = useMemo(
    () => [
      ...(!aggregations?.length ? ['Y-Axis Values'] : []),
      ...(!groupBys?.length ? ['X-Axis Grouping'] : []),
    ],
    [aggregations?.length, groupBys?.length],
  );

  if (missingText.length)
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_LINE_CHART_V2}>
        Missing {missingText.join(' and ')}
      </InvalidData>
    );

  const isArea = visualization === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2;
  const isAreaPercent = isArea && (view as AreaChartView)?.areaChart?.isPercent;

  return (
    <LineChart
      area={isArea}
      backgroundColor=""
      colorTracker={colorCategoryTracker}
      dataPanelProvidedId=""
      dataPanelTemplateId=""
      datasetData={{}}
      datasetNamesToId={{}}
      drilldownEntryPoints={{}}
      generalOptions={{ enableRawDataDrilldown: !!onSelect }}
      globalStyleConfig={globalStyleConfig}
      instructions={instructions}
      loading={isLoading}
      normalize={isAreaPercent}
      operationType={isAreaPercent ? OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2 : visualization}
      previewData={previewData}
      schema={schema}
      setChartMenu={onSelect || noOp}
      variables={variables}
    />
  );
};

const noOp = () => null;
