import { Button, IButtonProps, Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { CSSProperties, MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import { sprinkles } from 'components/ds';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { buttonContainerBackgroundClass } from 'globalStyles/sharedStyles.css';

import * as styles from './DropdownButton.css';

type PassedProps = {
  children?: ReactNode;
  className?: string;
  showCancelBtn?: boolean;
  ignoreCustomStyles?: boolean;
  onCancelClick?: () => void;
  style?: CSSProperties;
  hideText?: boolean;
};

type Props = IButtonProps & PassedProps;

const DropdownButton = (props: Props) => {
  const {
    disabled,
    icon,
    text,
    onCancelClick,
    showCancelBtn,
    hideText,
    ignoreCustomStyles,
    ...otherProps
  } = props;

  // we don't have custom disabled states, so we need to override custom styles if
  // the component is disabled for now
  const useCustomStylesIfNotDisabled = !props.ignoreCustomStyles && !disabled;
  return (
    <div className={sprinkles({ position: 'relative' })}>
      <Button
        disabled={disabled}
        {...otherProps}
        className={cx(
          styles.root,
          {
            disabled,
            [GLOBAL_STYLE_CLASSNAMES.text.body.input]: !ignoreCustomStyles,
            [buttonContainerBackgroundClass]: useCustomStylesIfNotDisabled,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownInputBorder]: !ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownInputBorderHover]:
              !ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputBorder]:
              !ignoreCustomStyles,
          },
          props.className,
        )}>
        <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
          <div
            className={cx(
              styles.buttonText,
              { [styles.buttonTextWithCancelBtn]: showCancelBtn },
              { [sprinkles({ display: 'flex' })]: icon },
            )}>
            {icon ? (
              <div className={cx(styles.buttonIcon, { noText: hideText })}>
                <Icon icon={icon} />
              </div>
            ) : null}
            {hideText ? undefined : text}
          </div>
          {showCancelBtn ? <div className={styles.cancelButtonSpacing} /> : null}
        </div>
      </Button>
      {showCancelBtn ? (
        <Button
          minimal
          className={styles.cancelButton}
          disabled={disabled}
          icon="cross"
          onClick={(e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            onCancelClick?.();
          }}
        />
      ) : null}
    </div>
  );
};

export default DropdownButton;
