import { TypeFooterRow } from '@inovua/reactdatagrid-enterprise/types';
import cx from 'classnames';
import { FC, useMemo } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedPivotTable } from 'components/embed';
import * as gridStyles from 'components/embed/EmbedDataGrid/index.css';
import { usePivotColumns } from 'components/embed/EmbedPivotTable/usePivotColumns';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { ColumnConfigs } from 'types/columnTypes';
import { DatasetRow, DatasetSchema } from 'types/datasets';

import * as styles from './index.css';
import { getFooterRow, transformRowsWithCellStyles } from './pivotUtils';

type Props = {
  // Column configs for formatting
  columnConfigs: ColumnConfigs;
  groupByColumns: string[];
  loading: boolean;
  pivotColumns: string[];
  rows: DatasetRow[] | undefined;
  schema: DatasetSchema;
  showSummary: boolean;
  keyPathToSummary: Record<string, DatasetRow>;
  summaryColumnName: string;
  isRowLimitReached?: boolean;
  pivotColumnAlignment: string;
};

export const ExplorePivotTable: FC<Props> = ({
  columnConfigs,
  loading,
  schema,
  rows,
  groupByColumns,
  pivotColumns,
  showSummary,
  keyPathToSummary,
  summaryColumnName,
  isRowLimitReached,
  pivotColumnAlignment,
}) => {
  const reportPivotColumns = usePivotColumns({
    groupByColumns,
    pivotColumns,
    schema,
    columnConfigs: columnConfigs,
    keyPathToSummary,
    showSummary,
    summaryColumnName,
  });

  const transformedRows = useMemo(
    () =>
      transformRowsWithCellStyles(
        rows,
        schema,
        columnConfigs,
        pivotColumns,
        showSummary,
        summaryColumnName,
      ),
    [rows, schema, columnConfigs, pivotColumns, showSummary, summaryColumnName],
  );

  const footerRows = useMemo<TypeFooterRow[]>(
    () =>
      getFooterRow(
        schema,
        columnConfigs,
        showSummary,
        keyPathToSummary,
        `${pivotColumns[0]}:${summaryColumnName}`,
      ),
    [schema, columnConfigs, pivotColumns, showSummary, keyPathToSummary, summaryColumnName],
  );

  if (!reportPivotColumns) return <></>;

  return (
    <div
      className={cx(
        gridStyles.container,
        embedSprinkles({ backgroundColor: 'containerFill' }),
        sprinkles({ parentContainer: 'fill', flexItems: 'column' }),
        styles.explorePivotTableContainer,
        pivotColumnAlignment,
      )}>
      <EmbedPivotTable
        {...reportPivotColumns}
        activeIndex={-1}
        className={sprinkles({ flex: 1 })}
        footerRows={footerRows}
        groupByColumns={groupByColumns || []}
        isRowLimitReached={isRowLimitReached}
        loading={loading}
        pivotColumns={pivotColumns || []}
        rowClassName={cx(gridStyles.hoverRow, gridStyles.row)}
        rows={transformedRows}
        schema={schema}
        showHoverRows={false}
      />
    </div>
  );
};
