import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CustomerReportGroupBy, ScatterPlotView } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { OPERATION_TYPES } from 'constants/types';
import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import {
  customerReportToScatterPlotInstructions,
  getScatterPlotConfigError,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import { ScatterPlot } from 'pages/dashboardPage/charts/ScatterPlot';
import { getCurrentTheme } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';
import { DatasetColumn, DatasetRow } from 'types/datasets';
type Props = {
  groupBys?: CustomerReportGroupBy[];
  columnGroupBys?: CustomerReportGroupBy[];
  columnConfigs?: ReportBuilderColConfigs;
  reportData: ReportData;
  schema: DatasetColumn[];
  view: ScatterPlotView;
};

export const ReportScatterPlot: FC<Props> = ({
  columnGroupBys,
  columnConfigs,
  reportData: { isLoading, rows },
  groupBys,
  schema,
  view,
}) => {
  const { variables, globalStyleConfig } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: getCurrentTheme(state.embeddedReportBuilder),
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () =>
      customerReportToScatterPlotInstructions(
        groupBys,
        columnGroupBys,
        view.scatterPlotGrouping,
        view.scatterPlot,
        columnConfigs,
      ),
    [groupBys, columnGroupBys, view.scatterPlotGrouping, view.scatterPlot, columnConfigs],
  );

  const missingText = useMemo(
    () => [
      ...(!groupBys?.length ? ['X-axis'] : []),
      ...(!columnGroupBys?.length ? ['Y-axis'] : []),
    ],
    [groupBys?.length, columnGroupBys?.length],
  );

  const invalidConfigText = useMemo(() => {
    return getScatterPlotConfigError(
      view.groupBys?.[0],
      view.columnGroupBys?.[0],
      view.scatterPlotGrouping,
    );
  }, [view.columnGroupBys, view.groupBys, view.scatterPlotGrouping]);

  if (missingText.length || invalidConfigText) {
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2}>
        {missingText.length ? `Missing ${missingText.join(' and ')}` : invalidConfigText}
      </InvalidData>
    );
  }

  return (
    <ScatterPlot
      backgroundColor=""
      datasetData={{}}
      datasetNamesToId={{}}
      globalStyleConfig={globalStyleConfig}
      instructions={instructions}
      loading={isLoading}
      previewData={rows as DatasetRow[]}
      schema={schema}
      variables={variables}
    />
  );
};
