import { DateTime } from 'luxon';
import parse from 'url-parse';
import XRegExp from 'xregexp';

import { DatasetDataObject } from 'actions/datasetActions';
import { Customer, EmbedCustomer } from 'actions/teamActions';
import { EmbeddedDashboardType, shouldUseUrlParams } from 'components/EmbeddedDashboard/types';
import { IconName } from 'components/ds/Icon';
import { ColumnTooltips } from 'components/embed/EmbedDataGrid';
import {
  COLOR_CATEGORY_FILTER_SUFFIX,
  DATE_ELEMENT_SET,
  NONE_CATEGORY_COLOR_VALUE,
  SELECT_ELEMENT_SET,
} from 'constants/dashboardConstants';
import { DATE_PART_INPUT_AGG, V2_VIZ_INSTRUCTION_TYPE } from 'constants/dataConstants';
import {
  FilterValueSourceType,
  GoalLineChartConfig,
  HEAT_MAP_COLOR_ZONE_OPERATIONS,
  INPUT_TYPE,
  KPI_NUMBER_TREND_OPERATION_TYPES,
  OPERATION_TYPES,
  SECTION_OPTIONS,
  SchemaChange,
  TableJoinColumnConfig,
  TrendGroupToggleOptionId,
  V2_CHART_GOAL_LINE_OPERATIONS,
  V2_COLOR_ZONE_OPERATIONS,
} from 'constants/types';
import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DateGroupToggleConfig,
  DateRangePickerElemConfig,
  DatepickerElemConfig,
  RowDrilldownVariable,
  SelectElemConfig,
  SliderElementConfig,
  SwitchElementConfig,
  TextInputElemConfig,
  TimePeriodDropdownElemConfig,
} from 'types/dashboardTypes';
import { EditableSectionConfig } from 'types/dashboardVersionConfig';
import {
  DEFAULT_DATE_TYPES,
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getQueryTablesReferencedByText, variableRegex } from 'utils/dataPanelConfigUtils';
import { getSliderThumbVariableName } from 'utils/sliderUtils';
import { cloneDeep, get, set } from 'utils/standard';

import { getCalendarHeatmapKeys } from './calendarHeatmapUtils';
import { isChartUsingMultipleColorCategories } from './colorColUtils';
import { getDatasetsByName } from './datasetUtils';
import { getDefaultRangeValues, getDefaultRelativeValue } from './dateUtils';
import { isChartAvailableToCustomer, isDataPanelLinked } from './editableSectionUtils';
import { getDataPanelDatasetId } from './exploResourceUtils';
import { getDisplayVarName, getLengthVarName } from './extraVariableUtils';
import { getDataPanelLinks } from './filterLinking';
import { getSelectFilterDatasetId } from './filterUtils';
import { getGeneralFormatFromVisualizationInstructions } from './graphUtils';
import { isSelectableKPI } from './selectableKpiUtils';

export const getDatasetIdsDependentOnVariable = (
  datasetIds: string[],
  datasetsById: Record<string, ResourceDataset>,
  changedElementNamesSet: Set<string>,
) => {
  if (changedElementNamesSet.size === 0) return datasetIds;

  return datasetIds.filter((datasetId) => {
    const dataset = datasetsById[datasetId];
    return isQueryDependentOnVariable(changedElementNamesSet, dataset);
  });
};

export const getElemsReliantOnVariableChange = (
  elemsWithDefaults: DashboardElement[],
  datasetsById: Record<string, ResourceDataset>,
  changedElementNamesSet: Set<string>,
) => {
  return elemsWithDefaults.filter((elem) => {
    const config = elem.config as SelectElemConfig;
    const datasetId = getSelectFilterDatasetId(config);
    if (!datasetId || !(datasetId in datasetsById)) return false;

    const dataset = datasetsById[datasetId];
    return isQueryDependentOnVariable(changedElementNamesSet, dataset);
  });
};

export const isQueryDependentOnVariable = (
  changedElementNamesSet: Set<string>,
  dataset: ResourceDataset | undefined,
): boolean => {
  if (!dataset || changedElementNamesSet.size === 0) return false;
  if ('query_variables' in dataset && dataset.query_variables) {
    return !!dataset.query_variables.find((variable) => {
      if (changedElementNamesSet.has(variable)) return true;
      return changedElementNamesSet.has(variable.split('.')[0]);
    });
  } else if (dataset.query) {
    let matchingVars = false;
    XRegExp.forEach(dataset.query, variableRegex, (match) => {
      const varName = match[2]?.trim();
      if (varName && changedElementNamesSet.has(varName)) matchingVars = true;
      if (changedElementNamesSet.has(varName.split('.')[0])) matchingVars = true;
    });
    return matchingVars;
  }
  return false;
};

export const getDataPanelsDependentOnVariable = (
  dataPanelsById: Record<string, DataPanel>,
  datasetsById: Record<string, ResourceDataset>,
  allElements: DashboardElement[],
  changedElementNamesSet: Set<string>,
  variables: DashboardVariableMap,
) => {
  if (changedElementNamesSet.size === 0) return [];

  const dpLinks = getDataPanelLinks(allElements, changedElementNamesSet);
  return Object.values(dataPanelsById).filter((dp) =>
    isDpReliantOnVariable(
      dp,
      datasetsById,
      dataPanelsById,
      dpLinks,
      changedElementNamesSet,
      variables,
    ),
  );
};

const isDpReliantOnVariable = (
  dataPanel: DataPanel,
  datasetsById: Record<string, ResourceDataset>,
  dataPanelsById: Record<string, DataPanel>,
  dpLinks: Record<string, Set<string> | undefined>,
  changedVars: Set<string>,
  variables: DashboardVariableMap,
) => {
  if (isConfigDependentOnVariable(changedVars, dataPanel, variables, dataPanelsById)) return true;

  const datasetIds = getDatasetIdsForDataPanel(dataPanel, undefined, true);

  for (let idx = 0; idx < datasetIds.length; idx++) {
    const datasetId = datasetIds[idx];

    if (isDataPanelLinked(dpLinks[datasetId], dataPanel.id)) return true;

    const dataset = datasetsById[datasetId];
    if (isQueryDependentOnVariable(changedVars, dataset)) return true;
  }

  return false;
};

// Logic must match prepareDataPanelForFetch since that function fetches the data while this checks if it needs to
export const isConfigDependentOnVariable = (
  changedElementNamesSet: Set<string>,
  dataPanel: DataPanel,
  variables: DashboardVariableMap,
  dataPanelsById: Record<string, DataPanel>,
) => {
  if (!dataPanel.visualize_op) return false;
  const filterClauses = dataPanel.filter_op?.instructions.filterClauses;

  if (
    filterClauses?.some(({ filterValueSource, filterValueVariableId, conditionalFilterConfig }) => {
      const isFilterTiedToVariable =
        filterValueSource === FilterValueSourceType.VARIABLE &&
        changedElementNamesSet.has(filterValueVariableId || '');
      if (isFilterTiedToVariable) return true;

      if (!conditionalFilterConfig?.isConditional) return false;
      const dataPanels = conditionalFilterConfig.chartsConditionalOn;

      return dataPanels?.some((panelId) => {
        const conditionedPanel = dataPanelsById[panelId];
        return (
          conditionedPanel &&
          isSelectableKPI(conditionedPanel) &&
          changedElementNamesSet.has(conditionedPanel.provided_id)
        );
      });
    })
  )
    return true;

  if (
    filterClauses?.some((filterClause) => {
      return Array.from(changedElementNamesSet).some((elem) => {
        const varId = filterClause.filterValueVariableId;
        if (!varId) return false;

        if (varId.endsWith('.category')) {
          if (varId.replace('.category', '') === elem) return true;
        } else if (varId.endsWith('.color') && varId.replace('.color', '') === elem) {
          return true;
        }

        return false;
      });
    })
  )
    return true;

  const vizInstructionType = V2_VIZ_INSTRUCTION_TYPE[dataPanel.visualize_op.operation_type];
  const twoDimensionInstructions = dataPanel.visualize_op.instructions.V2_TWO_DIMENSION_CHART ?? {};

  if (vizInstructionType === 'Two-dimensional') {
    if (
      twoDimensionInstructions.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG &&
      changedElementNamesSet.has(twoDimensionInstructions.categoryColumn.bucketElemId || '')
    )
      return true;

    const variableId = twoDimensionInstructions.categoryColumn?.bucket?.variableId;
    if (changedElementNamesSet.has(variableId || '')) return true;
  }

  const colorColOptions = twoDimensionInstructions.colorColumnOptions;
  const coloVarName = dataPanel.provided_id + COLOR_CATEGORY_FILTER_SUFFIX;
  let foundColorConfigChangedElement = false;
  if (colorColOptions?.length) {
    changedElementNamesSet.forEach((elem) => {
      if (elem === coloVarName) {
        foundColorConfigChangedElement = true;
      } else {
        colorColOptions.forEach((colorCol) => {
          if (variables[elem] === colorCol.column.name && elem.includes(dataPanel.provided_id)) {
            foundColorConfigChangedElement = true;
          }
        });
      }
    });
  }

  if (
    (vizInstructionType === 'Two-dimensional' ||
      vizInstructionType === 'Grouped Stacked Bar Chart') &&
    foundColorConfigChangedElement
  )
    return true;

  const operationType = dataPanel.visualize_op.operation_type;

  if (KPI_NUMBER_TREND_OPERATION_TYPES.has(operationType)) {
    const config = dataPanel.visualize_op.instructions.V2_KPI_TREND;
    if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT &&
      changedElementNamesSet.has(config.periodColumn.rangeElemId || '')
    )
      return true;

    if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN &&
      changedElementNamesSet.has(config.periodColumn.timePeriodElemId || '')
    )
      return true;

    if (config?.periodColumn?.periodRange === PeriodRangeTypes.CUSTOM_RANGE_VARIABLES) {
      return (
        changedElementNamesSet.has(
          removeBracesFromVariableString(config.periodColumn.customStartDateVariable || ''),
        ) ||
        changedElementNamesSet.has(
          removeBracesFromVariableString(config.periodColumn.customEndDateVariable || ''),
        )
      );
    }

    if (operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL) {
      const hasChangedDateRangeElem =
        config?.periodComparisonRange === PeriodComparisonRangeTypes.PREVIOUS_DATE_RANGE_INPUT &&
        changedElementNamesSet.has(config.periodColumn?.comparisonInfo?.rangeElemId || '');
      const hasChangedTimePeriodElem =
        config?.periodComparisonRange ===
          PeriodComparisonRangeTypes.PREVIOUS_TIME_PERIOD_DROPDOWN &&
        changedElementNamesSet.has(config.periodColumn?.comparisonInfo?.timePeriodElemId || '');
      if (hasChangedDateRangeElem || hasChangedTimePeriodElem) return true;
    }

    if (
      config?.trendGrouping === TrendGroupToggleOptionId &&
      changedElementNamesSet.has(config.trendGroupingElementId || '')
    )
      return true;
  }

  const { monthKey, yearKey } = getCalendarHeatmapKeys(dataPanel.provided_id);

  if (
    operationType === OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP &&
    (changedElementNamesSet.has(monthKey) || changedElementNamesSet.has(yearKey))
  )
    return true;

  return false;
};

export const isJoinConfigReady = (colConfig: TableJoinColumnConfig) => {
  return colConfig.joinOn && colConfig.joinColumn && colConfig.joinDisplayColumn;
};

// TODO(SHIBA-6003): Write tests for getDatasetIdsForDataPanel.
export const getDatasetIdsForDataPanel = (
  dataPanel: DataPanel,
  datasets: Record<string, ResourceDataset> | undefined,
  includeOwnDataset = false,
): string[] => {
  const dpDatasetId = getDataPanelDatasetId(dataPanel);
  const startingId = includeOwnDataset ? [dpDatasetId] : [];
  const datasetIds = new Set<string>(startingId);
  const datasetsByName = datasets ? getDatasetsByName(datasets) : undefined;

  const vizOp = dataPanel.visualize_op;
  const { instructions, generalFormatOptions } = vizOp;

  const checkStringAndAppendToDependentDataIds = (str: string | undefined) => {
    if (!str || !datasetsByName) return;
    getQueryTablesReferencedByText(str, datasetsByName).forEach((id) => datasetIds.add(id));
  };

  // add any datasets from the data panel title
  const headerConfig = generalFormatOptions?.headerConfig ?? {};
  if (!headerConfig.isHeaderHidden) checkStringAndAppendToDependentDataIds(headerConfig.title);

  const generalFormat = getGeneralFormatFromVisualizationInstructions(
    vizOp.operation_type,
    instructions,
  );
  if (generalFormat?.showTooltip) {
    checkStringAndAppendToDependentDataIds(generalFormat.tooltipText);
  }

  // add operation type misc specific panels
  const opType = vizOp.operation_type;
  if (opType === OPERATION_TYPES.VISUALIZE_TABLE) {
    Object.values(instructions.VISUALIZE_TABLE.schemaDisplayOptions || {}).forEach((colConfig) => {
      if (!(isJoinConfigReady(colConfig) && colConfig.joinTable?.id)) return;
      datasetIds.add(colConfig.joinTable.id);
    });
  } else if (
    opType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    opType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
  ) {
    if (opType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2) {
      checkStringAndAppendToDependentDataIds(
        String(instructions.V2_KPI?.valueFormat?.progressGoal || ''),
      );
    }
    checkStringAndAppendToDependentDataIds(instructions.V2_KPI?.generalFormat?.subtitle);
  } else if (KPI_NUMBER_TREND_OPERATION_TYPES.has(opType)) {
    checkStringAndAppendToDependentDataIds(instructions.V2_KPI_TREND?.textOnlyFormat?.subtitle);
  } else if (
    V2_VIZ_INSTRUCTION_TYPE[opType] === 'Two-dimensional' ||
    opType === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2 ||
    opType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2
  ) {
    // Data Labels can have variables in them
    instructions.V2_TWO_DIMENSION_CHART?.aggColumns?.forEach((col) =>
      checkStringAndAppendToDependentDataIds(col.column.friendly_name),
    );
  }

  if (V2_CHART_GOAL_LINE_OPERATIONS.has(opType)) {
    addGoalLineDatasets(datasetIds, datasetsByName, instructions.V2_TWO_DIMENSION_CHART);
  } else if (opType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    addGoalLineDatasets(datasetIds, datasetsByName, instructions.V2_BOX_PLOT);
  } else if (opType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    addGoalLineDatasets(datasetIds, datasetsByName, instructions.V2_SCATTER_PLOT);
  }
  if (V2_COLOR_ZONE_OPERATIONS.has(opType) || HEAT_MAP_COLOR_ZONE_OPERATIONS.has(opType)) {
    instructions.V2_TWO_DIMENSION_CHART?.colorFormat?.colorZones?.forEach((zone) =>
      checkStringAndAppendToDependentDataIds(zone.zoneThreshold),
    );
  } else if (opType === OPERATION_TYPES.VISUALIZE_DENSITY_MAP) {
    instructions.VISUALIZE_GEOSPATIAL_CHART?.densityMapFormat?.colorFormat?.colorZones?.forEach(
      (zone) => checkStringAndAppendToDependentDataIds(zone.zoneThreshold),
    );
  }

  // These options are only for PDF exports, but it's too much work atm to pass down whether we're inside a pdf export or not
  // For now, we'll just accept the performance penalty of loading a dataset we may not need
  if (
    opType === OPERATION_TYPES.VISUALIZE_TABLE ||
    opType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
  ) {
    const pdfFormat = generalFormatOptions?.export?.pdfFormat;

    if (pdfFormat?.headerEnabled) {
      if (pdfFormat.centerOption === SECTION_OPTIONS.TEXT)
        checkStringAndAppendToDependentDataIds(pdfFormat.centerContent);
      if (pdfFormat.leftOption === SECTION_OPTIONS.TEXT)
        checkStringAndAppendToDependentDataIds(pdfFormat.leftContent);
      if (pdfFormat.rightOption === SECTION_OPTIONS.TEXT)
        checkStringAndAppendToDependentDataIds(pdfFormat.rightContent);
    }
  }

  return Array.from(datasetIds);
};

const addGoalLineDatasets = (
  datasetIds: Set<string>,
  datasetsByName: Record<string, ResourceDataset> | undefined,
  goalLineConfig?: GoalLineChartConfig,
) => {
  if (!datasetsByName) return;
  goalLineConfig?.goalLines?.forEach((goalLine) =>
    getQueryTablesReferencedByText(goalLine.goalValue, datasetsByName)
      .concat(getQueryTablesReferencedByText(goalLine.goalValueMax, datasetsByName))
      .forEach((id) => datasetIds.add(id)),
  );
};

export const getDefaultVariablesFromDashElements = (
  elems: DashboardElement[],
  timezone: string,
  variablesDefaultValues?: DashboardVariableMap,
) => {
  const variables: Record<string, DashboardVariable> = {};

  elems.forEach((elem) => {
    if (SELECT_ELEMENT_SET.has(elem.element_type)) {
      const { valuesConfig } = elem.config as SelectElemConfig;
      if (valuesConfig.valuesSource === INPUT_TYPE.MANUAL && valuesConfig.manualDefaultValue) {
        try {
          const isMultiSelect = elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT;

          const manualValues: DashboardVariable[] = JSON.parse(valuesConfig.manualValues);
          const manualDisplayValues: DashboardVariable[] = JSON.parse(valuesConfig.manualDisplays);

          const valueOverride = variablesDefaultValues?.[elem.name];
          if (valueOverride === undefined) {
            const defaultValue = isMultiSelect
              ? JSON.parse(valuesConfig.manualDefaultValue as string)
              : valuesConfig.manualDefaultValue;

            if (defaultValue === undefined) return;

            variables[elem.name] = defaultValue;
            if (isMultiSelect) {
              variables[getLengthVarName(elem.name)] = (defaultValue as string[] | number[]).length;
            } else {
              const valueIdx = manualValues.findIndex((val) => val === defaultValue);
              if (valueIdx < 0) return;
              variables[getDisplayVarName(elem.name)] = manualDisplayValues[valueIdx];
            }
            return;
          }

          const overrideIdx = manualValues.findIndex((val) => val === valueOverride);
          if (overrideIdx < 0) {
            console.error(
              `Invalid value ${valueOverride} passed for variable ${elem.name}. Ensure that the type of the input (e.g. number) matches the type of the values in the editor.`,
            );
          } else {
            variables[elem.name] = valueOverride;
            if (isMultiSelect) {
              variables[getLengthVarName(elem.name)] = (
                valueOverride as string[] | number[]
              ).length;
            } else {
              variables[getDisplayVarName(elem.name)] = manualDisplayValues[overrideIdx];
            }
          }

          return;
        } catch {
          return;
        }
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH) {
      const config = elem.config as SwitchElementConfig;
      if (config.defaultOn) {
        variables[elem.name] = config.onStatusValue || 'true';
      } else {
        variables[elem.name] = config.offStatusValue || 'false';
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.SLIDER) {
      const config = elem.config as SliderElementConfig;
      const sliderValues: Record<string, number> = {};
      for (let i = 0; i < config.numThumbs; i++) {
        const key = getSliderThumbVariableName(i);
        sliderValues[key] = config.defaultValue[key];
      }
      variables[elem.name] = sliderValues;
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN) {
      const config = elem.config as TimePeriodDropdownElemConfig;
      if (config.defaultValue) {
        // only set the default value if there is an option that represents it in the values config
        const selectedOption = config.values.find((option) => option.value === config.defaultValue);
        if (selectedOption) variables[elem.name] = config.defaultValue;
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATEPICKER) {
      const config = elem.config as DatepickerElemConfig;
      if (config.defaultType === DEFAULT_DATE_TYPES.EXACT) {
        if (config.defaultValue) variables[elem.name] = config.defaultValue;
      } else if (config.relativeDefaultValue) {
        variables[elem.name] = getDefaultRelativeValue(config.relativeDefaultValue, timezone);
      }
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
      const config = elem.config as DateRangePickerElemConfig;
      if (!config.defaultDateRange) return;

      variables[elem.name] = getDefaultRangeValues(
        config.defaultDateRange,
        config.endDateEndOfDay,
        config.presetRanges,
        timezone,
      );
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
      const config = elem.config as DateGroupToggleConfig;
      variables[elem.name] = config.defaultGroupingOption || TrendGroupingOptions.MONTHLY;
    } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.TEXT_INPUT) {
      const config = elem.config as TextInputElemConfig;
      if (config.defaultValue) variables[elem.name] = config.defaultValue;
    }
  });

  return variables;
};

export const initializeDpFilterVariables = (dps: DataPanel[]) => {
  let variables: Record<string, DashboardVariable> = {};

  dps.forEach((dp) => {
    variables = { ...variables, ...initializeVariablesForDataPanel(dp) };
  });

  return variables;
};

export const initializeEditableSectionFilterVariables = (
  editableSection: EditableSectionConfig,
  customer: Customer | EmbedCustomer,
) => {
  let variables: Record<string, DashboardVariable> = {};

  if (!editableSection || !editableSection.enabled) {
    return variables;
  }

  Object.values(editableSection.charts).forEach((chart) => {
    if (isChartAvailableToCustomer(chart, customer)) {
      variables = { ...variables, ...initializeVariablesForDataPanel(chart.data_panel) };
    }
  });

  return variables;
};

const initializeVariablesForDataPanel = (dp: DataPanel) => {
  const variables: Record<string, DashboardVariable> = {};

  if (!isChartUsingMultipleColorCategories(dp.visualize_op)) return variables;
  const twoDInstructions = dp.visualize_op.instructions.V2_TWO_DIMENSION_CHART ?? {};
  variables[dp.provided_id + COLOR_CATEGORY_FILTER_SUFFIX] =
    twoDInstructions.defaultColorGroupingOff
      ? NONE_CATEGORY_COLOR_VALUE
      : twoDInstructions.colorColumnOptions?.[0].column.name;

  return variables;
};

type TooltipInfo = { showTooltip?: boolean; infoTooltipText?: string };

export const resolveTooltipVariables = (
  { showTooltip, infoTooltipText }: TooltipInfo,
  variables: DashboardVariableMap | undefined,
  datasetNameToIds: Record<string, string>,
  datasetData: DatasetDataObject,
): string | undefined => {
  if (!showTooltip) return;
  const tooltipText = infoTooltipText?.trim() ?? '';
  return replaceVariablesInString(tooltipText, variables, datasetNameToIds, datasetData);
};

/** This is used to replace inputs that previously only expected number inputs
 * but now allow for variables and dataset variables as inputs
 */
export const resolveNumberInputWithVariables = (
  value: string | undefined,
  variables: DashboardVariableMap | undefined,
  datasetNamesToId: Record<string, string> | undefined,
  datasetData: DatasetDataObject | undefined,
) => {
  // Safety check in case something was missed in the migration to change type to string
  if (typeof value === 'number') {
    console.error(
      `resolveNumberInputWithVariables is called with value ${value} that is not a string`,
    );
    return value;
  }

  const trimmedValue = value?.trim();
  if (!trimmedValue) return;

  const processedValue = parseFloat(
    replaceVariablesInString(trimmedValue, variables, datasetNamesToId, datasetData),
  );

  return isNaN(processedValue) ? undefined : processedValue;
};

const VARS_TO_EXCLUDE = new Set(['refresh-minutes', 'userTransformedSchema', 'timezone']);

export function getQueryVariables(
  embedType: EmbeddedDashboardType | undefined,
  updateUrlParams?: boolean,
) {
  if (!shouldUseUrlParams(embedType, updateUrlParams)) return {};

  const rawVars = parse(window.location.href, true).query;
  if (!rawVars) return {};

  const queryVariables: DashboardVariableMap = {};
  Object.entries(rawVars).forEach(([key, val]) => {
    if (!val || VARS_TO_EXCLUDE.has(key)) return;

    try {
      queryVariables[key] = JSON.parse(val);
    } catch {
      // Fall back to treating param as string, but replace all non-word characters
      queryVariables[key] = val.replace(/\W/g, '');
    }
  });

  return queryVariables;
}

/**
 * Unwraps query variables values for dashboard elements. This is done to separate the dashboard
 * element variable value from its properties to ensure that the property values are not overridden.
 * E.g. for date range pickers, a user can specify the minDate and maxDate properties in the query
 * and these properties would be overridden if they were considered part of the date range picker
 * variable value (which only includes start and end date properties).
 * @param elementNameToElementMap A mapping of dashboard element names (not ids) to the elements.
 * @return An object that contains the unwrapped query variables and a boolean indicating whether
 *    any variable values were unwrapped. If no variables were unwrapped, the original variables are
 *    equal to the returned unwrapped variables.
 */
export function unwrapQueryVariablesForDashboardElements(
  variables: DashboardVariableMap,
  elementNameToElementMap: Record<string, DashboardElement>,
) {
  const elementsNameSet = new Set(Object.keys(elementNameToElementMap));
  const unwrappedUrlVariables: DashboardVariableMap = {};
  Object.entries(variables).forEach(([variableName, variableValue]) => {
    // Only try to unwrap variables that are objects (excluding arrays).
    if (
      !elementsNameSet.has(variableName) ||
      typeof variableValue !== 'object' ||
      Array.isArray(variableValue)
    ) {
      unwrappedUrlVariables[variableName] = variableValue;
      return;
    }

    // This is a lie to a compiler but we know that the variable value is an object.
    const elementVariableValue = variableValue as RowDrilldownVariable;
    const element = elementNameToElementMap[variableName];

    const elementType = element.element_type;
    const variableSubPropertyFields =
      DASHBOARD_ELEMENT_TYPE_TO_PROPERTY_FIELDS[elementType] ?? new Set();
    Object.keys(elementVariableValue).forEach((elementVariableSubValueKey) => {
      const elementVariableSubValue = elementVariableValue[elementVariableSubValueKey];
      if (variableSubPropertyFields.has(elementVariableSubValueKey)) {
        unwrappedUrlVariables[`${variableName}.${elementVariableSubValueKey}`] =
          elementVariableSubValue;
      } else {
        const prevDashboardElementVariableValue =
          (unwrappedUrlVariables[variableName] as RowDrilldownVariable) ?? {};
        unwrappedUrlVariables[variableName] = {
          ...prevDashboardElementVariableValue,
          [elementVariableSubValueKey]: elementVariableSubValue,
        };
      }
    });
  });

  return unwrappedUrlVariables;
}

// The property fields for dashboard elements that must be unwrapped when set as a variable so that
// they are not overridden on variable value setting.
const DASHBOARD_ELEMENT_TYPE_TO_PROPERTY_FIELDS: Record<string, Set<string>> = {
  [DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER]: new Set(['minDate', 'maxDate']),
};

/**
 * Wraps query variable values for dashboard elements. Dashboard element values can be specified
 * in dot notation (dashboardElementName.valueProperty) and these must be wrapped together as a
 * JSON object (dashboardElement = { valueProperty: value }) so that the dashboard element can
 * correctly consume the variable value.
 * @param elementNameToElementMap A mapping of dashboard element names (not ids) to the elements.
 * @returns An object that contains the wrapped query variables and a boolean indicating whether
 * variables were wrapped. If no variables were wrapped, the original variables are equal to the
 * return variables.
 */
export function wrapQueryVariablesForDashboardElements(
  variables: DashboardVariableMap,
  elementNameToElementMap: Record<string, DashboardElement>,
) {
  const wrappedUrlVariables: DashboardVariableMap = {};
  Object.entries(variables).forEach(([variableName, variableValue]) => {
    const variableNameSplitByDot = variableName.split('.');
    const elementName = variableNameSplitByDot[0];
    const element = elementNameToElementMap[elementName];
    // Check if the variable is an unwrapped element variable (should have the format
    // elementName.property). If not, set the variable as is.
    if (!element) {
      wrappedUrlVariables[variableName] = variableValue;
      return;
    }

    const elementType = element.element_type;
    const valueFields = DASHBOARD_ELEMENT_TYPE_TO_VALUE_FIELDS[elementType];
    // Keep the unwrapped element variable if its not a value property that needs to be wrapped.
    if (!valueFields) {
      wrappedUrlVariables[variableName] = variableValue;
      return;
    }
    const elementVariableProperty = variableNameSplitByDot[1];
    if (valueFields.has(elementVariableProperty)) {
      set(wrappedUrlVariables, variableName, variableValue);
    } else {
      wrappedUrlVariables[variableName] = variableValue;
    }
  });

  return wrappedUrlVariables;
}

// The value fields for dashboard elements that must be wrapped as an object when set as a variable
// so that their value is correctly used by the dashboard element and set when the variable value is
// set.
const DASHBOARD_ELEMENT_TYPE_TO_VALUE_FIELDS: Record<string, Set<string>> = {
  [DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER]: new Set(['startDate', 'endDate']),
};

// Get tooltip text with variables for each column in changeSchemaList
export const getTooltipVariables = (
  changeSchemaList: SchemaChange[],
  variables?: DashboardVariableMap,
) => {
  const columnTooltips: ColumnTooltips = {};
  changeSchemaList.forEach(({ col, showTooltip, tooltipText }) => {
    if (showTooltip && tooltipText) {
      columnTooltips[col] = variables
        ? replaceVariablesInString(tooltipText, variables)
        : tooltipText;
    }
  });
  return columnTooltips;
};

export const getRefreshMinutes = (refreshMinutes: number | undefined) =>
  parseFloat(
    // our docs accidentally specified refresh_minutes as the var name, so support both
    getValueOrDefault('refresh_minutes', getValueOrDefault('refresh-minutes', refreshMinutes)),
  ) || undefined;

export const getValueOrDefault = (searchValue: string, value?: string | number | boolean) => {
  if (value != null) return value;

  const rawVars = parse(window.location.href, true).query;
  const rawVar = rawVars?.[searchValue];
  try {
    if (rawVar != null) return JSON.parse(rawVar);
  } catch {
    if (typeof rawVar === 'string') return rawVar;
  }
};

export const filterHiddenElements = (
  elements: Record<string, DashboardElement> | undefined,
  hiddenElements: Set<string>,
): DashboardElement[] => {
  const elementList = Object.values(elements ?? {});

  return hiddenElements.size
    ? elementList.filter((elem) => !hiddenElements.has(elem.name))
    : elementList;
};

export const filterHiddenPanels = (
  dataPanels: Record<string, DataPanel> | undefined,
  hiddenElements: Set<string>,
): Record<string, DataPanel> => {
  if (!dataPanels || !hiddenElements.size) return dataPanels ?? {};
  const visiblePanels: Record<string, DataPanel> = {};
  Object.keys(dataPanels).forEach((dpId) => {
    const dp = dataPanels[dpId];
    if (hiddenElements.has(dp.provided_id)) return;
    visiblePanels[dpId] = dp;
  });
  return visiblePanels;
};

export const isElementHidden = (variable: DashboardVariable): boolean => {
  if (variable === undefined) return false;
  variable = String(variable);
  return variable.toLowerCase() === 'true';
};

// Since we also get from iframe just have an exhaustive check of different
// ways user could pass in truthy values
export const isVariableTrue = (variable: DashboardVariable): boolean => {
  if (!variable) return false;
  if (typeof variable === 'boolean' && variable === true) return true;
  if (typeof variable !== 'string') return false;

  return String(variable).toLowerCase() === 'true';
};

export const isVariableFalse = (variable: DashboardVariable): boolean => {
  if (!variable) return false;
  if (typeof variable === 'boolean' && variable === false) return true;
  if (typeof variable !== 'string') return false;

  return String(variable).toLowerCase() === 'false';
};

export const getVariableIcon = (
  value: DashboardVariable,
  elementType?: DASHBOARD_ELEMENT_TYPES,
): IconName => {
  if (elementType && DATE_ELEMENT_SET.has(elementType)) return 'calendar';
  if (elementType === DASHBOARD_ELEMENT_TYPES.MULTISELECT) return 'list';
  if (typeof value === 'number') return 'report-builder-number';
  if (typeof value === 'boolean') return 'tick';
  if (DateTime.isDateTime(value)) return 'calendar';
  return 'report-builder-string';
};

/**
 * Returns a variable mapping using any overriden values for the given data panel. Overridden variables
 * are prefixed by the provided_id of the data panel and otherwise match the key exactly
 */
export const getDataPanelQueryContext = (dataPanel: DataPanel, variables: DashboardVariableMap) => {
  const queryContext = cloneDeep(variables);

  Object.keys(variables).forEach((key) => {
    const chartVariable = getVariableForDataPanel(key, dataPanel);
    if (chartVariable) queryContext[chartVariable] = queryContext[key];
  });

  return queryContext;
};

/**
 * If a variable is related to a data panel, get the variable key for the data panel
 * For example, if variableKey is 'chart_id.var_name.startDate' and dataPanel.provided_id='chart_id',
 * then this will return 'var_name.startDate'. But if dataPanel is unrelated, returns 'chart_id.var_name.startDate'
 *
 * @param variableKey
 * @param dataPanel
 */
const getVariableForDataPanel = (variableKey: string, dataPanel: DataPanel) => {
  const { variableId, property } = parseVariableId(variableKey);
  // If property is undefined,the variable isn't related to the data panel even though the variableId matches
  if (variableId === dataPanel.id || variableId === dataPanel.provided_id) return property;
};

export const parseVariableId = (id: string) => {
  const firstDotIndex = id.indexOf('.');
  const variableId = firstDotIndex === -1 ? id : id.substring(0, firstDotIndex);
  const property = firstDotIndex === -1 ? undefined : id.substring(firstDotIndex + 1);
  return { variableId, property };
};

export const replaceVariablesInString = (
  s: string,
  passedVariables: DashboardVariableMap | null | undefined,
  datasetNameToIds: Record<string, string> = {},
  datasetData?: DatasetDataObject,
) => {
  // we still want to iterate to clear out variable phrases if no variables
  // are passed
  const variables = passedVariables ?? {};

  //@ts-ignore
  XRegExp.forEach(s, variableRegex, (match) => {
    const varName = match[2]?.trim();
    if (varName) {
      let replacement = '';
      if (varName in variables) {
        const value = variables[varName];
        replacement = value === undefined ? '' : String(value);
      } else if (varName.indexOf('.') > -1) {
        const splitVar = varName.split('.');
        // If there are two parts to the variable, and the first part is a dataset name
        // then we know to replace the value with a dataset value.
        if (datasetData && splitVar.length === 2 && splitVar[0] in datasetNameToIds) {
          const [datasetName, columnName] = splitVar;
          const datasetId = datasetNameToIds[datasetName];
          const rows = datasetData[datasetId]?.rows;

          // just use the value in the first row because we're leaving it to the
          // customer to do any aggregation or work in SQL before passing the table
          // to this component
          replacement =
            // explicitly check undefined for the actual value because 0 is falsy,
            // but would be a valid value we want to display, for example
            rows?.[0]?.[columnName] !== undefined
              ? String(rows[0][columnName])
              : rows?.[0]?.[columnName.toUpperCase()] !== undefined
              ? String(rows[0][columnName.toUpperCase()])
              : '';
        } else {
          // If we reached here, then there splitVar can be any length and so we just check the list
          // against `variables` and try to pull out a value if possible. If a value doesn't come
          // then we default to varName
          replacement = get(variables, splitVar, '');
        }
      }

      s = s.replace(match[0], replacement);
    }
  });
  return s;
};

export const removeBracesFromVariableString = (s: string) => {
  const match = XRegExp.exec(s, variableRegex);
  if (match === null) {
    return s;
  }
  return match[2].trim();
};
