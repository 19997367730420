import { Layout } from '@explo-tech/react-grid-layout';
import { DateTime } from 'luxon';
import { DateTimeUnit } from 'luxon/src/datetime';

import { Dashboard } from 'actions/dashboardActions';
import { Timezones } from 'constants/dashboardConstants';
import { INTEGER_DATA_TYPE, STRING } from 'constants/dataConstants';
import { INPUT_TYPE, SortOption } from 'constants/types';

import { DashboardVersion } from './dashboardVersion';
import {
  DATE_RANGE_TYPES,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_TYPES,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_RANGES,
  TrendGroupingOptions,
} from './dateRangeTypes';
import { FilterOperator } from './filterOperations';

export enum TEXT_ELEM_HORIZ_ALIGNMENTS {
  LEFT = 'LEFT_ALIGN',
  CENTER = 'CENTER_ALIGN',
  RIGHT = 'RIGHT_ALIGN',
}

export enum VERTICAL_CONTENT_ALIGNMENTS {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum TEXT_ELEM_VERTICAL_ALIGNMENTS {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum TITLE_VALUE_ARRANGEMENTS {
  ABOVE = 'TITLE_ABOVE',
  BELOW = 'TITLE_BELOW',
  FIXED_LEFT = 'TITLE_FIXED_TOP_LEFT',
  FIXED_CENTER = 'TITLE_FIXED_TOP_CENTER',
}

export enum TEXT_ELEM_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum DASHBOARD_ELEMENT_TYPES {
  DATA_PANEL = 'DATA_PANEL',

  TEXT = 'TEXT',
  SPACER = 'SPACER',
  DROPDOWN = 'DROPDOWN',
  TIME_PERIOD_DROPDOWN = 'TIME_PERIOD_DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  DATEPICKER = 'DATEPICKER',
  DATE_RANGE_PICKER = 'DATE_RANGE_PICKER',
  EXPORT = 'EXPORT',
  CONTAINER = 'CONTAINER',
  TOGGLE = 'TOGGLE',
  DATE_GROUP_SWITCH = 'DATE_GROUP_SWITCH',
  IMAGE = 'IMAGE',
  SWITCH = 'SWITCH',
  APPLY_FILTER_BUTTON = 'APPLY_FILTER_BUTTON',
  TEXT_INPUT = 'TEXT_INPUT',
  IFRAME = 'IFRAME',
  REFRESH_BUTTON = 'REFRESH_BUTTON',
  EVENT_BUTTON = 'EVENT_BUTTON',
  SLIDER = 'SLIDER',
}

export enum DASHBOARD_LAYOUT_CONFIG {
  DASHBOARD_BODY = 'DASHBOARD_BODY',
  HEADER = 'HEADER',
}

export type SELECT_FILTER_TYPE =
  | DASHBOARD_ELEMENT_TYPES.DROPDOWN
  | DASHBOARD_ELEMENT_TYPES.MULTISELECT
  | DASHBOARD_ELEMENT_TYPES.TOGGLE;

export type DATE_FILTER_TYPE =
  | DASHBOARD_ELEMENT_TYPES.DATEPICKER
  | DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER;

export type DateTimeRangeDashboardVariable = { startDate: DateTime; endDate: DateTime };
// DateRangeStringDashboardVariable used when loading query parameters from URLs, such as share links or page URLs for screenshots
export type DateRangeStringDashboardVariable = { startDate: string; endDate: string };

export type DashboardVariable =
  | string
  | number
  | string[]
  | number[]
  | number[][]
  | string[][]
  | undefined
  | DateTime
  | DateTimeRangeDashboardVariable
  | DateRangeStringDashboardVariable
  | DrilldownVariable
  | RowDrilldownVariable
  | SliderElementValue;

// TODO(zifanxiang): Make this more generic, drilldowns can be more than just a category and color.
export type DrilldownVariable = { category: DashboardVariable; color?: DashboardVariable };
// Record is of column name to column value
export type RowDrilldownVariable = Record<string, string | number>;

export type DashboardVariableMap = Record<string, DashboardVariable>;

export type TextElemHorizAlignments =
  | TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER
  | TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
  | TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT;

export type VerticalContentAlignments =
  | VERTICAL_CONTENT_ALIGNMENTS.TOP
  | VERTICAL_CONTENT_ALIGNMENTS.CENTER
  | VERTICAL_CONTENT_ALIGNMENTS.BOTTOM;

export type TextElemVerticalAlignments =
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.CENTER
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.BOTTOM;

export type TitleValueArrangements =
  | TITLE_VALUE_ARRANGEMENTS.ABOVE
  | TITLE_VALUE_ARRANGEMENTS.BELOW
  | TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT
  | TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER;

export type TextDashboardElemConfig = {
  text?: string;
  renderMarkdown?: boolean;
  renderHTML?: boolean;
  alignmentHoriz: TextElemHorizAlignments;
  alignmentVertical: TextElemVerticalAlignments;
  textColor: string;
  textSize?: TEXT_ELEM_SIZES;
};

export type DropdownValuesConfig = {
  valuesSource: INPUT_TYPE;
  queryTable?: { id: string };
  queryValueColumn?: { name: string };
  queryDisplayColumn?: { name: string };
  queryDefaultFirstValue?: boolean;
  querySortOption?: SortOption;
  querySortByValue?: boolean;
  manualValues: string;
  manualDisplays: string;
  manualDefaultValue?: string | number;
};

export type LabelWithTooltipConfig = {
  label: string;
  showTooltip?: boolean;
  infoTooltipText?: string;
};

export type SelectElemConfig = LabelWithTooltipConfig & {
  disableCancel?: boolean;
  disableOnNoItems?: boolean;
  disableSearch?: boolean;
  filterPlaceholderText?: string;
  placeholderText?: string;
  valuesConfig: DropdownValuesConfig;
};

export type TimePeriodDropdownElemConfig = LabelWithTooltipConfig & {
  placeholderText?: string;
  enableSearch?: boolean;
  enableCancel?: boolean;
  values: { value: number; name: string }[];
  defaultValue?: number;
};

type SharedDateConfig = LabelWithTooltipConfig & {
  disableCancel?: boolean;
  minValue?: DateTime;
  maxValue?: DateTime;
  dateRangeType?: DATE_RANGE_TYPES;
  relativeDateRange?: RELATIVE_DATE_RANGES;
  hideTimeSelect?: boolean;
};

export type CustomDatePresetId = string;

export type DateRangeId = CustomDatePresetId | DEFAULT_DATE_RANGES;

export enum DateOperationType {
  Now = 'now',
  Plus = 'plus',
  Minus = 'minus',
  StartOf = 'startOf',
  EndOf = 'endOf',
}

export type DateNowOperation = {
  id: DateOperationType.Now;
};

export type DateAdditionOperation = {
  id: DateOperationType.Plus | DateOperationType.Minus;
  unit: DateTimeUnit;
  quantity: number;
};

export type DatePeriodOperation = {
  id: DateOperationType.StartOf | DateOperationType.EndOf;
  unit: DateTimeUnit;
};

export type DateOperation = DateAdditionOperation | DatePeriodOperation | DateNowOperation;

// Customer-defined date range presets that end users can choose from
// TODO: Add support for Report Builder in frontend and backend
export type DateRangePresetConfig = {
  name: string;
  startDate: { operations: DateOperation[] };
  endDate: { operations: DateOperation[] };
};

export type DateRangePickerElemConfig = {
  defaultDateRange?: DateRangeId;
  includeRangeDropdown?: boolean;
  excludeDatePicker?: boolean;
  endDateEndOfDay?: boolean;
  hiddenDefaultRanges?: DateRangeId[];
  // Explained more in dateRangeUtils
  newRangesToShow?: DateRangeId[];
  defaultRangesOrder?: DateRangeId[];
  presetRanges?: Record<CustomDatePresetId, DateRangePresetConfig>;
} & SharedDateConfig;

export type DatepickerElemConfig = {
  defaultType?: DEFAULT_DATE_TYPES;
  defaultValue?: string | DateTime;
  relativeDefaultValue?: RELATIVE_DATE_OPTIONS;
} & SharedDateConfig;

export type DateElemConfig = DatepickerElemConfig & DateRangePickerElemConfig;

export type SwitchElementConfig = LabelWithTooltipConfig & {
  offStatusLabel?: string;
  offStatusValue?: string;
  onStatusLabel?: string;
  onStatusValue?: string;
  showStatusLabel: boolean;
  showSwitchLabel?: boolean;
  defaultOn?: boolean;
  switchValueType?: typeof STRING | typeof INTEGER_DATA_TYPE;
};

export type SliderElementConfig = LabelWithTooltipConfig & {
  min: string; // number or variable
  max: string; // number or variable
  numThumbs: number;
  stepSize: number;
  showSliderLabel?: boolean;
  showThumbLabels?: boolean;
  defaultValue: SliderElementValue;
};

export type SliderElementValue = Record<string, number>;

export type ExportElemConfig = {
  label: string;
  popoverTitle?: string;
  popoverSubtitle?: string;
  passwordEnabled: boolean;
  usernameEnabled: boolean;
  siteTitle?: string;
  faviconUrl?: string;
  disableShareLink?: boolean;
  disableImageDownload?: boolean;
  disablePdfDownload?: boolean;
  downloadDashboardFileName?: string;
};

export type ContainerElemConfig = {
  layout: Layout[];
  pdfLayout?: Layout[];
  emailLayout?: Layout[];
  mobileLayout?: Layout[];
};

export type ImageElemConfig = {
  imageUrl?: string;
  fitEntireImage?: boolean;
};

export enum DateGroupToggleSelectTypes {
  DROPDOWN = 'Dropdown',
  TOGGLE = 'Toggle',
}

export type DateGroupToggleConfig = LabelWithTooltipConfig & {
  groupingOptionByType?: Record<string, { name?: string; exclude?: boolean }>;
  defaultGroupingOption: TrendGroupingOptions;
  filterType?: DateGroupToggleSelectTypes;
};

export interface DashboardButtonElemConfig {
  buttonText?: string;
  // This field controls whatever icon this component uses, not just the refresh icon
  hideRefreshIcon?: boolean;
}

export interface EventButtonElemConfig extends DashboardButtonElemConfig {
  jsEvent?: string;
}

export interface ApplyFilterElemConfig extends DashboardButtonElemConfig {
  elementIds?: Record<string, boolean>;
  hideNumFiltersIcon?: boolean;
}

export type TextInputElemConfig = {
  label?: string;
  placeholderText?: string;
  showSearchIcon?: boolean;
  defaultValue?: string;
  limitEntriesToNumbers?: boolean;
};

export type IframeElemConfig = {
  iframeUrl?: string;
};

type ElementDependencyConfig = {
  dependencyElementIds?: Record<string, boolean>;
  ignoreDependencies?: boolean;
};

type FilterLinkingConfig = {
  operator?: FilterOperator;
  datasetLinks?: Record<string, DatasetLinkConfig>;
};

type DatasetLinkConfig = {
  column?: string;
  dataPanels?: string[];
};

export type DashboardElementConfig = (
  | TextDashboardElemConfig
  | SelectElemConfig
  | DateElemConfig
  | ExportElemConfig
  | SwitchElementConfig
  | SliderElementConfig
  | ContainerElemConfig
  | ImageElemConfig
  | DateGroupToggleConfig
  | TimePeriodDropdownElemConfig
  | ApplyFilterElemConfig
  | TextInputElemConfig
  | IframeElemConfig
  | EventButtonElemConfig
) &
  ElementDependencyConfig &
  FilterLinkingConfig;

export interface DashboardElement {
  id: string;
  name: string;
  element_type: DASHBOARD_ELEMENT_TYPES;
  container_id?: string;
  config: DashboardElementConfig;
  elemLocation?: DASHBOARD_LAYOUT_CONFIG; // defaults to DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY
}

export type NumberColumnMetrics = {
  min?: number;
  avg?: number;
  max?: number;
};
export interface MetricsByColumn {
  [columnName: string]: NumberColumnMetrics;
}

export enum VIEW_MODE {
  DEFAULT = 'default',
  SHARE = 'share',
  PDF = 'pdf',
  MOBILE = 'mobile',
  EMAIL = 'email',
}

export enum EDIT_MODE_HASH {
  EDIT = '#edit',
  PDF = '#edit_pdf',
  MOBILE = '#edit_mobile',
  EMAIL = '#edit_email',
}

export enum PAGE_TYPE {
  EXPLO_APP = 'explo app',
  EMBEDDED = 'embedded',
  SHARED = 'shared',
  END_USER_DASHBOARD = 'end user dashboard',
}

export type DashboardHierarchy = {
  dashboards: Record<string, Dashboard>;
  rootDashboardId: number;
};

export type EmbedDashboardHierarchy = {
  dashboards: Record<string, EmbedDashboard>;
  rootDashboardId: number;
};

export type DashboardVersionHierarchy = {
  // A map of dashboard id to dashboard version. NOTE: The key here is not the version id but the
  // dashboard id.
  dashboardVersions: Record<number, DashboardVersion>;
  rootDashboardId: number;
};

export const DASHBOARD_RESPONSE_KEY_RENAME_MAP: Record<string, string> = {
  root_dashboard_id: 'rootDashboardId',
  child_dashboards: 'childDashboardIds',
  parent_dashboard: 'parentDashboardId',
};

export interface EmbedDashboard {
  id: number;
  name: string;
  disable_filters_while_loading: boolean;
  // TODO(tarastentz): Remove this once we are sure the backend is serving it
  should_values_share_colors_across_dashboard?: boolean;
  should_persist_customer_state: boolean;
  default_timezone: Timezones | undefined;
  childDashboardIds: number[];
  parentDashboardId: number | null;
}
