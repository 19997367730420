import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs, sprinkles, Tag } from 'components/ds';
import { BreadcrumbsHierarchyItem } from 'components/ds/Breadcrumbs';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  DrilldownDatasetFilter,
  setCurrentSourceInfos,
  setDrilldownFilters,
} from 'reducers/drilldownsReducer';
import { switchCurrentEmbedDashboard } from 'reducers/embedDashboardReducer';
import { DashboardStates } from 'reducers/rootReducer';
import {
  getCurrentDashboardWithDrilldowns,
  getDashboardHierarchy,
  getDashboardVersionHierarchy,
  isEmbedDashboard,
} from 'reducers/selectors';
import * as RD from 'remotedata';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'types/filterOperations';
import { cloneDeep } from 'utils/standard';

interface Props {
  globalStyleConfig: GlobalStyleConfig;
}

export const DashboardDrilldownsBanner: FC<Props> = ({ globalStyleConfig }) => {
  const dispatch = useDispatch();

  const {
    currentSourceInfos,
    drilldownDatasetFilters,
    dashboardHierarchy,
    dashboardVersionHierarchy,
    currentDashboard,
    isEmbed,
  } = useSelector((state: DashboardStates) => {
    return {
      currentSourceInfos: state.drilldowns.currentSourceInfos,
      drilldownDatasetFilters: state.drilldowns.drilldownDatasetFilters,
      dashboardVersionHierarchy: getDashboardVersionHierarchy(state),
      dashboardHierarchy: getDashboardHierarchy(state),
      currentDashboard: getCurrentDashboardWithDrilldowns(state),
      isEmbed: isEmbedDashboard(state),
    };
  }, shallowEqual);

  const dashboardBreadcumbInfo = useMemo(() => {
    if (!RD.isSuccess(dashboardHierarchy)) {
      return [];
    }

    return currentSourceInfos.map((info) => {
      const dashboard = dashboardHierarchy.data.dashboards[info.sourceDashboardId];

      if (!dashboard) {
        return {};
      }

      return {
        dashboardId: dashboard.id,
        dashboardName: dashboard.name,
        drilldownEntryPointId: info.drilldownEntryPointId,
      };
    });
  }, [currentSourceInfos, dashboardHierarchy]);

  const breadcrumbToDrilldownVariablesMap = useMemo(() => {
    if (!RD.isSuccess(dashboardVersionHierarchy)) {
      return new Map();
    }

    const breadcrumbToDrilldownVariablesMap = new Map();

    const currentDrilldownDatasetFilters: Record<string, DrilldownDatasetFilter> = {};
    dashboardBreadcumbInfo.forEach((breadcrumbInfo, index) => {
      // Set the drilldown dataset filters for the current breadcrumb based on the drilldown entry
      // point of the previous breadcrumb since that is where the drilldown filters that apply to
      // the dashboard that will be navigated to from the breadcrumb originate.
      const previousSourceInfo = currentSourceInfos[index - 1];
      if (!previousSourceInfo) {
        return;
      }

      const sourceDashboardId = previousSourceInfo.sourceDashboardId;
      const originDataPanel =
        dashboardVersionHierarchy.data.dashboardVersions[sourceDashboardId].configuration
          .data_panels[previousSourceInfo.sourceDataPanelId];
      const drilldownEntryPoint =
        originDataPanel.drilldownEntryPoints[previousSourceInfo.drilldownEntryPointId];
      const drilldownDatasetFilterForDataset = drilldownDatasetFilters[originDataPanel.table_id];

      if (!currentDrilldownDatasetFilters[originDataPanel.table_id]) {
        currentDrilldownDatasetFilters[originDataPanel.table_id] = {};
      }

      const drilldownSourceColumnNames = new Set(
        drilldownEntryPoint.sourceChartColumns.map((column) => column.name),
      );
      Object.entries(drilldownDatasetFilterForDataset).forEach(([columnName, filterInfos]) => {
        // Only take the dataset filters that are set by the previous breadcrumb.
        if (!drilldownSourceColumnNames.has(columnName)) {
          return;
        }

        currentDrilldownDatasetFilters[originDataPanel.table_id][columnName] = filterInfos;
      });

      // Clone the current drilldown dataset filters since this is iterating through the breadcrumbs
      // in navigation order and will be appending additional dataset filters defined by subsequent
      // breadcrumbs.
      breadcrumbToDrilldownVariablesMap.set(
        breadcrumbInfo,
        cloneDeep(currentDrilldownDatasetFilters),
      );
    });

    return breadcrumbToDrilldownVariablesMap;
  }, [
    currentSourceInfos,
    dashboardBreadcumbInfo,
    dashboardVersionHierarchy,
    drilldownDatasetFilters,
  ]);

  const breadcrumbHierarchyItems = useMemo(() => {
    const breadcrumbs: BreadcrumbsHierarchyItem[] = [];
    dashboardBreadcumbInfo.forEach((breadcrumbInfo, index) => {
      const previousDashboardId = breadcrumbInfo.dashboardId;
      if (!previousDashboardId) return;
      // TODO(zifanxiang/tarastentz): Preserve the drilldown variables as well as the source infos.
      const previousSourceInfos = currentSourceInfos.slice(0, index);
      const previousDrilldownFilters = breadcrumbToDrilldownVariablesMap.get(breadcrumbInfo);
      breadcrumbs.push({
        text: breadcrumbInfo.dashboardName,
        link: !isEmbed
          ? {
              pathname: `/dashboard/${previousDashboardId}`,
              state: {
                updatedDrilldownSourceInfos: previousSourceInfos,
                updatedDrilldownDatasetFilters: previousDrilldownFilters,
              },
            }
          : undefined,
        onClick: isEmbed
          ? () => {
              dispatch(setCurrentSourceInfos(previousSourceInfos));
              dispatch(setDrilldownFilters(previousDrilldownFilters));
              dispatch(
                switchCurrentEmbedDashboard({
                  currentDashboardId: previousDashboardId,
                  variables: {},
                }),
              );
            }
          : undefined,
      });
    });
    // Add current dashboard info, but no linking behavior
    if (RD.isSuccess(currentDashboard)) breadcrumbs.push({ text: currentDashboard.data.name });
    return breadcrumbs;
  }, [
    dashboardBreadcumbInfo,
    currentSourceInfos,
    breadcrumbToDrilldownVariablesMap,
    isEmbed,
    currentDashboard,
    dispatch,
  ]);

  if (dashboardBreadcumbInfo.length === 0 || !RD.isSuccess(currentDashboard)) {
    return null;
  }

  return (
    <div
      className={containerClass}
      style={{ backgroundColor: globalStyleConfig.base.backgroundColor }}>
      <div className={sprinkles({ heading: 'h3', paddingRight: 'sp8' })}>
        <Breadcrumbs items={breadcrumbHierarchyItems} />
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter', marginX: 'sp8' })}>
        {Object.values(drilldownDatasetFilters).map((datasetFilter) => {
          return Object.entries(datasetFilter).map(([columnName, filterInfos]) => {
            return filterInfos.map((filterInfo) => {
              const { filterValue, operatorId } = filterInfo;
              const renderedFilterValue =
                filterValue instanceof Object ? JSON.stringify(filterValue) : filterValue;
              return (
                <Tag
                  className={sprinkles({ marginX: 'sp1' })}
                  key={`${columnName}-${filterInfo.filterValue}`}
                  leftIcon="filter">
                  {columnName} {FILTER_OPERATOR_TYPES_BY_ID[operatorId].name} {renderedFilterValue}
                </Tag>
              );
            });
          });
        })}
      </div>
    </div>
  );
};

const containerClass = sprinkles({
  flexItems: 'alignCenter',
  paddingX: 'sp3',
  paddingTop: 'sp2',
  gap: 'sp8',
});
