import Highcharts, { AxisTypeValue, PointOptionsObject } from 'highcharts';
import { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ConnectedProps, connect } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { NONE_CATEGORY_COLOR_VALUE, Timezones } from 'constants/dashboardConstants';
import { DATE_TYPES } from 'constants/dataConstants';
import {
  ColumnColorTracker,
  LineElasticity,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  filterLineChartDataToCategories,
  truncateCategoriesToMaxCategories,
} from 'pages/dashboardPage/charts/utils/filterDataUtils';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { AllStates } from 'reducers/rootReducer';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DrilldownEntryPointInfo, FilterOperation } from 'types/dataPanelTemplate';
import { DatasetSchema } from 'types/datasets';
import { PivotAgg } from 'types/dateRangeTypes';
import {
  getColorFromPaletteTracker,
  getColorTrackerCategoryName,
} from 'utils/colorCategorySyncUtils';
import { getColorColumn, isSelectedColorDateType } from 'utils/colorColUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { insertZeroesForMissingDateData } from 'utils/dateUtils';
import { getDrilldownChartVariable, setCategorySelect } from 'utils/drilldownUtils';
import { sortBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { TwoDimensionalDataPanelUtils } from 'utils/twoDimensionalDataPanelUtils';
import { replaceVariablesInString } from 'utils/variableUtils';

import { NeedsConfigurationPanel } from '../needsConfigurationPanel';

import { SeriesOptions } from './constants/types';
import { DrilldownChart } from './shared/drilldownChart';
import {
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  formatLegend,
  formatValue,
  getAxisNumericalValue,
  getColorColNames,
  getColorPalette,
  getColorZones,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  shouldProcessColAsDate,
  xAxisFormat,
} from './utils';
import {
  createYAxisBaseTooltip,
  getMultiYAxisInstructions,
  getSingleYAxisInstructions,
  getValueFormat,
  getYAxisChartIndex,
  getYAxisFormatById,
} from './utils/multiYAxisUtils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

type Props = {
  backgroundColor: string;
  colorTracker?: ColumnColorTracker;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  filterOperation?: FilterOperation;
  dataPanelTemplateId: string;
  canUseMultiYAxis?: boolean;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  selectedColorColName?: string;
  grouped?: boolean;
  horizontal?: boolean;
  area?: boolean;
  normalize?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  operationType: OPERATION_TYPES;
  dataPanelProvidedId: string;
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
  setChartMenu: (info: ChartMenuInfo | null) => void;
  setVariable?: (value: DashboardVariable) => void;
} & PropsFromRedux;

class LineChart extends PureComponent<Props> {
  render() {
    const {
      dataPanelProvidedId,
      generalOptions,
      instructions,
      loading,
      variables,
      operationType,
      selectedColorColName,
      setVariable,
    } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(
      instructions,
      operationType,
    );

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    const drilldownVariable = getDrilldownChartVariable(
      variables,
      instructions,
      dataPanelProvidedId,
    );

    return (
      <DrilldownChart
        chartOptions={this._spec()}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
        }
        dataPanelId={this.props.dataPanelTemplateId}
        drilldownEntryPoints={this.props.drilldownEntryPoints}
        drilldownVar={drilldownVariable}
        instructions={instructions}
        selectedColorColName={this.props.selectedColorColName}
        setCategorySelect={
          instructions?.drilldown?.categorySelectEnabled
            ? (category: string, colorColumn?: string) =>
                setCategorySelect(
                  dataPanelProvidedId,
                  variables,
                  category,
                  colorColumn,
                  selectedColorColName,
                  setVariable,
                  instructions,
                )
            : undefined
        }
        underlyingDataEnabled={TwoDimensionalDataPanelUtils.isUnderlyingDataEnabled(generalOptions)}
      />
    );
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      area,
      normalize,
      backgroundColor,
      generalOptions,
      globalStyleConfig,
      canUseMultiYAxis,
      variables,
      selectedColorColName,
      setChartMenu,
      dataPanelTemplateId,
      datasetData,
      datasetNamesToId,
      operationType,
      drilldownEntryPoints,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const lineWidth =
      instructions?.chartSpecificFormat?.lineChart?.lineWidth ||
      globalStyleConfig.container.lineWidth.default;

    // TODO(SHIBA-6010): Support user-set max category
    const xAxisCategories = truncateCategoriesToMaxCategories(
      this.getAxisCategories(),
      instructions?.xAxisFormat,
    );

    const transformedData = this.transformData();

    const { data, categories: transformedXAxisCategories } = filterLineChartDataToCategories(
      transformedData,
      xAxisCategories,
      instructions?.xAxisFormat,
      DATE_TYPES.has(instructions?.categoryColumn?.column.type || ''),
    );
    const stacking = this.getStacking();
    const marker = { enabled: this.shouldShowMarkers() };

    const hasClickEvents = TwoDimensionalDataPanelUtils.doesChartHaveClickEvents(
      instructions,
      generalOptions,
      drilldownEntryPoints,
      operationType,
    );
    const colors = getColorPalette(globalStyleConfig, instructions?.colorFormat);

    this.applyAreaGradient(data, colors);

    const xAxisType = this.getXAxisType();
    return {
      chart: {
        type: this.getChartType(),
        zoomType: 'x',
        backgroundColor,
      },
      series: data,
      title: sharedTitleConfig,
      colors,
      plotOptions: {
        series: {
          zones: getColorZones(instructions?.colorFormat, variables, datasetNamesToId, datasetData),
          lineWidth,
          animation: false,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;

                const subCategory: string | undefined =
                  !!instructions?.colorColumnOptions?.length &&
                  selectedColorColName !== NONE_CATEGORY_COLOR_VALUE
                    ? // @ts-ignore
                      e.point.series.userOptions.rawColorData
                    : undefined;

                setChartMenu({
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  category: e.point.category,
                  subCategory,
                });
              },
            },
          },
          dataLabels: {
            enabled: !instructions?.xAxisFormat?.hideTotalValues,

            formatter: function () {
              return formatValue({
                value: this.y || 0,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },

            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
          },
          // this is needed for stacking to work properly
          connectNulls: area,
        },
        spline: { marker, dashStyle: this.getLineType() },
        areaspline: { stacking, marker },
        line: { marker, dashStyle: this.getLineType() },
        area: { stacking, marker },
      },
      yAxis: canUseMultiYAxis
        ? getMultiYAxisInstructions(
            globalStyleConfig,
            instructions,
            variables,
            datasetNamesToId,
            datasetData,
          )
        : getSingleYAxisInstructions(
            globalStyleConfig,
            instructions,
            variables,
            datasetNamesToId,
            datasetData,
          ),
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: xAxisType,
        crosshair: true,
        categories: DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')
          ? undefined
          : transformedXAxisCategories,
        labels: {
          formatter: function () {
            return formatLabel(
              this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.categoryColumn?.bucketSize,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
          rotation: instructions?.xAxisFormat?.rotationAngle,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
        // force highcharts to put the ticks on the quarters
        units:
          instructions?.categoryColumn?.bucket?.id === PivotAgg.DATE_QUARTER
            ? [['month', [3, 6, 12]]]
            : undefined,
      },
      legend: formatLegend(globalStyleConfig, instructions?.legendFormat),
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalStyleConfig,
              instructions,
              includePercent: (area && normalize) || instructions?.tooltipFormat?.showPct,
            }),
          );
        },
      },
    };
  };

  applyAreaGradient = (data: SeriesOptions[], colors: string[]): void => {
    const { area, instructions } = this.props;
    if (!area) return;

    const colorLen = colors.length;
    const useGradient = !!instructions?.chartSpecificFormat?.areaChart?.useGradientFill;

    data.forEach((series, idx) => {
      const areaSeries = series as Highcharts.SeriesAreaOptions;

      // Need this so that it changes back when toggling back off
      if (!useGradient) {
        areaSeries.color = areaSeries.color ?? undefined;
        areaSeries.fillColor = undefined;
        return;
      }
      const color = series.color || colors[idx % colorLen];
      areaSeries.color = color;

      areaSeries.fillColor = {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, Highcharts.color(color).setOpacity(0.15).get('rgba').toString()],
          [1, Highcharts.color(color).setOpacity(0).get('rgba').toString()],
        ],
      };
    });
  };

  getChartType = () => {
    const { area, instructions } = this.props;
    if (instructions?.chartSpecificFormat?.lineChart?.elasticity === LineElasticity.STRAIGHT) {
      return area ? 'area' : 'line';
    } else {
      return area ? 'areaspline' : 'spline';
    }
  };

  getLineType = () => {
    if (this.props.area) return;
    return this.props.instructions?.chartSpecificFormat?.lineChart?.lineType;
  };

  getStacking = () => (this.props.normalize ? 'percent' : 'normal');

  getXAxisType = (): AxisTypeValue | undefined => {
    const { instructions } = this.props;

    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
    return 'category';
  };

  getXAxisColName = () => this.props.schema[0].name;

  processDatesData = () => {
    const {
      instructions,
      previewData,
      schema,
      operationType,
      filterOperation,
      timezone,
      enableFillOuterDates,
    } = this.props;
    const categoryColIsDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const colorColIsDate = isSelectedColorDateType(instructions || {});

    if (!previewData || (!categoryColIsDate && !colorColIsDate) || !schema?.length) return;

    const { xAxisColName, colorColName } = getColorColNames(
      schema,
      operationType,
      !!instructions?.colorColumnOptions?.length,
    );

    if (instructions?.categoryColumn?.column.type) {
      previewData.forEach((row) => {
        // If it's a number, it has already been converted to milliseconds
        if (categoryColIsDate && typeof row[xAxisColName] !== 'number')
          row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);

        if (colorColIsDate && typeof row[colorColName] !== 'number')
          row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
      });
    }

    if (instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingDates) {
      const aggColNames = schema.slice(1).map((col) => col.name);
      const colNames = { xAxisColName, yAxisColNames: aggColNames, colorColName };
      insertZeroesForMissingDateData(
        previewData,
        instructions.categoryColumn,
        colNames,
        enableFillOuterDates ? filterOperation : undefined,
        timezone,
      );
    }
  };

  getAxisCategories = () => {
    const { previewData } = this.props;

    const xAxisColName = this.getXAxisColName();
    const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
    return Array.from(categories);
  };

  transformData = (): SeriesOptions[] => {
    const { instructions, schema, selectedColorColName } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    if (
      instructions.colorColumnOptions?.length &&
      selectedColorColName !== NONE_CATEGORY_COLOR_VALUE
    )
      return this.transformColorData(schema);

    return this.transformAggColsData(schema);
  };

  transformColorData = (schema: DatasetSchema): SeriesOptions[] => {
    const { previewData, instructions, selectedColorColName, colorTracker, area, operationType } =
      this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema, operationType);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const series: Record<string, SeriesOptions> = {};
    const colorColumn = getColorColumn(instructions, selectedColorColName);
    const zeroEmptyValues =
      instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingValues;

    previewData.forEach((row) => {
      if (
        isDate &&
        (row[xAxisColName] === undefined || isNaN(getAxisNumericalValue(row[xAxisColName])))
      )
        return;

      const colorValue = row[colorColName];
      const colorCategory = formatLabel(
        colorValue,
        colorColumn?.column.type,
        colorColumn?.bucket?.id,
      );

      const rawAggValue = row[aggColName];
      let aggValue: number;
      if (zeroEmptyValues && !rawAggValue) aggValue = 0;
      else {
        aggValue = getAxisNumericalValue(row[aggColName]);
        if (isNaN(aggValue)) return;
      }

      const entry = this.createSeriesEntry(xAxisColName, colorColName, row, isDate, aggValue);
      if (series[colorCategory]) {
        series[colorCategory].data.push(entry);
      } else {
        const category = getColorTrackerCategoryName(xAxisColName, colorColName);
        series[colorCategory] = {
          type: this.getChartType(),
          name: colorCategory,
          rawColorData: colorValue,
          data: [entry],
          color: getColorFromPaletteTracker({
            columnName: category,
            valueName: String(colorValue),
            colorTracker,
          }),
        };
      }
    });

    // Ensure stable legend sorting, not currently configurable
    const seriesData = sortBy(Object.values(series), (s) => s.name);

    if (isDate && instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData) {
      seriesData.forEach((series) => series.data.pop());
      return seriesData;
    }

    if (area && instructions?.chartSpecificFormat?.areaChart?.reverseGroupOrder) {
      seriesData.reverse();
    }
    return seriesData;
  };

  transformAggColsData = (schema: DatasetSchema): SeriesOptions[] => {
    const {
      instructions,
      previewData,
      canUseMultiYAxis,
      variables,
      datasetNamesToId,
      datasetData,
      colorTracker,
    } = this.props;
    const xAxisColName = schema[0].name;
    const aggCols = instructions?.aggColumns || [];
    const aggColNames = schema.map((col) => col.name).slice(1);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const series: Record<string, SeriesOptions> = {};
    const zeroEmptyValues =
      instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingValues;

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      aggColNames.forEach((colName, index) => {
        if (
          isDate &&
          (row[xAxisColName] === undefined || isNaN(getAxisNumericalValue(row[xAxisColName])))
        )
          return;
        const aggCol = aggCols[index];
        if (!aggCol) return;

        const rawAggValue = row[colName];
        let aggValue: number;
        if (zeroEmptyValues && !rawAggValue) aggValue = 0;
        else {
          aggValue = getAxisNumericalValue(row[colName]);
          if (isNaN(aggValue)) return;
        }

        const entry = this.createSeriesEntry(
          xAxisColName,
          /* colorColName= */ '',
          row,
          isDate,
          aggValue,
        );
        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          const name = aggCol.column.friendly_name
            ? replaceVariablesInString(
                aggCol.column.friendly_name,
                variables,
                datasetNamesToId,
                datasetData,
              )
            : getColDisplayText(aggCol) || colName;
          series[colName] = {
            type: this.getChartType(),
            name,
            data: [entry],
            dataLabels: {
              formatter: function () {
                const { valueFormatId, decimalPlaces } = getValueFormat(
                  getYAxisFormatById(instructions?.yAxisFormats, aggCol.yAxisFormatId) ||
                    instructions?.yAxisFormats?.[0], // fallback to the globally set yAxisFormat
                );
                return formatValue({
                  value: this.y || 0,
                  decimalPlaces,
                  formatId: valueFormatId,
                  hasCommas: true,
                });
              },
            },
            yAxis: getYAxisChartIndex(aggCol.yAxisFormatId, canUseMultiYAxis, instructions),
            color: aggCol.column.name
              ? getColorFromPaletteTracker({
                  columnName: colName,
                  valueName: aggCol.column.name,
                  colorTracker,
                })
              : undefined,
          };
        }
      });
    });

    // Ensure stable legend sorting, not currently configurable
    return sortBy(Object.values(series), (s) => s.name);
  };

  shouldShowMarkers = (): boolean =>
    !this.props.instructions?.chartSpecificFormat?.lineChart?.hideMarkers ||
    this.props.previewData.length === 1;

  /**
   * @param colorColName The color column name, pass in an empty string if there is no color column.
   */
  createSeriesEntry = (
    xAxisColName: string,
    colorColName: string,
    row: Record<string, string | number>,
    isXAxisColDateTyped: boolean,
    aggValue: number,
  ): PointOptionsObject => {
    const { variables, instructions, dataPanelProvidedId } = this.props;
    const drilldownVariable = getDrilldownChartVariable(
      variables,
      instructions,
      dataPanelProvidedId,
    );
    const selectedCategoryVariableValue = drilldownVariable?.category;
    const xAxisValue = isXAxisColDateTyped
      ? getAxisNumericalValue(row[xAxisColName])
      : String(row[xAxisColName]);
    const selectedColorVariableValue = drilldownVariable?.color;
    const colorColValueMatches = !colorColName || row[colorColName] === selectedColorVariableValue;
    const isSelected = xAxisValue === selectedCategoryVariableValue && colorColValueMatches;
    const baseEntry = {
      y: aggValue,
      selected: isSelected,
      // Override the marker enablement for the selected filter point so that the user can toggle
      // it on and off.
      marker: { enabled: this.shouldShowMarkers() || isSelected },
    };
    return isXAxisColDateTyped
      ? {
          x: xAxisValue as number,
          ...baseEntry,
        }
      : {
          name: xAxisValue as string,
          ...baseEntry,
        };
  };
}

const mapStateToProps = (state: AllStates) => {
  return {
    timezone:
      'dashboardLayout' in state ? state.dashboardLayout.requestInfo.timezone : Timezones.UTC,
    enableFillOuterDates:
      'dashboardLayout' in state ? state.dashboardLayout.enableFillMissingDates : false,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LineChart);
