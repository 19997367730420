import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  FetchDataPanelBody,
  FetchDataPanelRowCountBody,
  FetchSecondaryDataBody,
  updateUnderlyingDataDataPanel,
} from 'actions/dataPanelTemplateAction';
import { DatasetDataObject } from 'actions/datasetActions';
import { JobDefinition } from 'actions/jobQueueActions';
import {
  FetchDataPanelData,
  FetchDataPanelRowCountData,
  QueryDebuggingInformation,
} from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import {
  CategoryChartColumnInfo,
  KPI_NUMBER_TREND_OPERATION_TYPES,
  OPERATION_TYPES,
  ROW_COUNT_OPERATIONS_SET,
} from 'constants/types';
import { setDataPanelsLoading } from 'reducers/dashboardDataReducer';
import { DrilldownDatasetFilter } from 'reducers/drilldownsReducer';
import { DashboardStates } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { DashboardVariableMap, EmbedDashboard } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { FilterOperator } from 'types/filterOperations';
import { convertSortInfoToList, getFilterInfo, getSortInfo } from 'utils/adHocUtils';
import * as dashboardUtils from 'utils/dashboardUtils';
import { getDashboardTimezone } from 'utils/dashboardUtils';
import * as dpConfigUtils from 'utils/dataPanelConfigUtils';
import * as dateTimeUtils from 'utils/dateTimeUtils';
import { getDateBetweenFilterValues } from 'utils/dateTimeUtils';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import { useFidoForRequest } from 'utils/fido/fidoUtils';
import { attachLinkFiltersToDp } from 'utils/filterLinking';
import { cloneDeep } from 'utils/standard';
import {
  attachDatasetToPostData,
  createApiRequestConfigWithRequestInfo,
  makeThunkRequest,
} from 'utils/thunkUtils';
import { getDataPanelQueryContext } from 'utils/variableUtils';

import { enqueueDashboardJobsThunk } from '../dashboardLayoutThunks';
import { DashboardLayoutThunk } from '../dashboardLayoutThunks/types';

import { fetchFidoComputationDataThunk } from './fetchFidoDataThunks';
import { DashboardDataThunk } from './types';
import { DashboardConfig, getDashboardConfig } from './utils';

export const fetchDataPanelsThunk =
  (
    dataPanels: DataPanel[],
    config: DashboardConfig,
    shouldOverrideCache?: boolean,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const state = getState();
    const variables = state.dashboardData.variables ?? {};
    const dashboardTimezone = getDashboardTimezone(
      RD.getOrDefault(state.embedDashboard.dashboard, undefined),
    );

    const jobs: JobDefinition[] = [];

    const drilldownDatasetFilters = state.drilldowns.drilldownDatasetFilters;
    dataPanels.forEach((dataPanel) => {
      if (
        !dpConfigUtils.isDataPanelReadyToCompute(dataPanel, config.datasets) ||
        !dashboardUtils.areRequiredUserInputsSet(variables, dataPanel)
      ) {
        return dispatch(setDataPanelsLoading({ ids: [dataPanel.id], loading: false }));
      }

      const dataPanelVariables = getDataPanelQueryContext(dataPanel, variables);

      if (
        useFidoForRequest(
          state.dashboardLayout.requestInfo,
          state.fido,
          config.datasets[dataPanel.table_id],
        )
      ) {
        return dispatch(
          fetchFidoComputationDataThunk(
            dashboardUtils.prepareDataPanelForFetch(
              variables,
              dataPanel,
              config.datasets,
              state.dashboardData.datasetData,
              config.elements,
              config.dataPanels,
              drilldownDatasetFilters,
              false,
              dashboardTimezone,
            ),
            config.datasets,
            dataPanelVariables,
          ),
        );
      }

      const requiresPrimaryData = dpConfigUtils.dataPanelRequiresPrimaryData(
        dataPanel.visualize_op,
      );
      if (requiresPrimaryData)
        dispatch(
          fetchPrimaryData(
            dataPanel,
            config,
            jobs,
            dataPanelVariables,
            undefined,
            shouldOverrideCache,
          ),
        );

      dispatch(fetchSecondaryData(dataPanel, config, jobs, dataPanelVariables));

      if (ROW_COUNT_OPERATIONS_SET.has(dataPanel.visualize_op.operation_type)) {
        dispatch(fetchRowCountData(dataPanel, config, jobs, dataPanelVariables));
      }

      if (!requiresPrimaryData) {
        dispatch(setDataPanelsLoading({ ids: [dataPanel.id], loading: false }));
      }
    });
    if (jobs.length > 0) dispatch(enqueueDashboardJobsThunk({ jobs }));
  };

export const fetchPrimaryData =
  (
    dataPanel: DataPanel,
    config: DashboardConfig,
    jobs: JobDefinition[],
    variables: DashboardVariableMap,
    // This is used when instructions are updated, requests are slightly different
    adHocInstructions?: AdHocOperationInstructions,
    shouldOverrideCache?: boolean,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const state = getState();
    const requestInfo = state.dashboardLayout.requestInfo;

    const datasetId = getDataPanelDatasetId(dataPanel);
    const dataset = config.datasets[datasetId];
    if (!dataset) return;
    const dataPanelData = state.dashboardData.dataPanelData[dataPanel.id];

    const filterInfo = dateTimeUtils.shiftFilterInfoToTargetTimezoneForEmbeddo(
      adHocInstructions
        ? adHocInstructions.filterInfo
        : getFilterInfo(dataPanel.visualize_op.operation_type, dataPanelData),
      requestInfo.timezone,
      'local',
    );

    const sortInfo = adHocInstructions
      ? adHocInstructions.sortInfo
      : getSortInfo(dataPanel, dataPanelData);
    const pageNumber = adHocInstructions ? adHocInstructions.currentPage : undefined;
    const drilldownDatasetFilters = state.drilldowns.drilldownDatasetFilters;
    const postData: FetchDataPanelBody = {
      id: dataPanel.id,
      filter_info: filterInfo,
      sort_info: convertSortInfoToList(sortInfo),
      page_number: pageNumber,
      config: prepareDataPanelForFetchWrapper(
        dataPanel,
        config,
        variables,
        state.dashboardData.datasetData,
        drilldownDatasetFilters,
        false,
        RD.getOrDefault(state.embedDashboard.dashboard, undefined),
      ),
      variables,
      ...attachDatasetToPostData(dataPanel, config.datasets, requestInfo),
      should_override_cache: shouldOverrideCache,
      jobId: uuidv4(),
      query_limit: requestInfo.dataPanelMaxDataPoints,
    };

    if (requestInfo.useJobQueue) {
      const jobArgs = {
        ...postData,
        secondary_instructions: adHocInstructions
          ? undefined
          : dashboardUtils.getSynchronousSecondaryDataInstructions(dataPanel, undefined, true),
      };
      jobs.push({ job_type: ACTION.FETCH_DATA_PANEL_TEMPLATE, job_args: jobArgs });
    } else {
      const onSuccess = adHocInstructions
        ? undefined
        : ({ data_panel_template }: FetchDataPanelData) =>
            dispatch(
              fetchSecondaryData(
                dataPanel,
                config,
                [],
                variables,
                data_panel_template._source_type,
              ),
            );
      dispatch(fetchPrimaryDataThunk({ postData, onSuccess }));
    }
  };

export const fetchPrimaryDataThunk = createAsyncThunk<
  FetchDataPanelData,
  { postData: FetchDataPanelBody; onSuccess?: (data: FetchDataPanelData) => void },
  {
    state: DashboardStates;
    rejectValue: { error_msg: string; query_information: QueryDebuggingInformation };
  }
>(
  ACTION.FETCH_DATA_PANEL_TEMPLATE,
  async ({ postData, onSuccess }, { getState, rejectWithValue }) => {
    const requestInfo = getState().dashboardLayout.requestInfo;

    const urls = {
      embedUrl: `embed/get_data/`,
      appUrl: 'data_panel_templates/get_data/',
    };

    const requestConfig = createApiRequestConfigWithRequestInfo(
      urls,
      'POST',
      requestInfo,
      postData,
    );

    return makeThunkRequest(requestConfig, 'Error fetching data panel data', {
      onSuccess,
      rejectWithValue,
    });
  },
);

export const fetchRowCountData =
  (
    dataPanel: DataPanel,
    config: DashboardConfig,
    jobs: JobDefinition[],
    variables: DashboardVariableMap,
    adHocInstructions?: AdHocOperationInstructions,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const state = getState();
    const requestInfo = state.dashboardLayout.requestInfo;

    const dataPanelData = state.dashboardData.dataPanelData[dataPanel.id];

    const filterInfo = dateTimeUtils.shiftFilterInfoToTargetTimezoneForEmbeddo(
      adHocInstructions
        ? adHocInstructions.filterInfo
        : getFilterInfo(dataPanel.visualize_op.operation_type, dataPanelData),
      requestInfo.timezone,
      'local',
    );

    const drilldownDatasetFilters = state.drilldowns.drilldownDatasetFilters;
    const postData: FetchDataPanelRowCountBody = {
      id: dataPanel.id,
      filter_info: filterInfo,
      config: prepareDataPanelForFetchWrapper(
        dataPanel,
        config,
        variables,
        state.dashboardData.datasetData,
        drilldownDatasetFilters,
        false,
        RD.getOrDefault(state.embedDashboard.dashboard, undefined),
      ),
      variables,
      ...attachDatasetToPostData(dataPanel, config.datasets, requestInfo),
    };

    if (requestInfo.useJobQueue) {
      jobs.push({ job_type: ACTION.FETCH_DATA_PANEL_ROW_COUNT, job_args: postData });
    } else {
      dispatch(fetchRowCountDataThunk(postData));
    }
  };

export const fetchRowCountDataThunk = createAsyncThunk<
  FetchDataPanelRowCountData,
  FetchDataPanelRowCountBody,
  { state: DashboardStates }
>(ACTION.FETCH_DATA_PANEL_ROW_COUNT, async (args, { getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/get_row_count/`,
    appUrl: 'data_panel_templates/get_row_count/',
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error fetching table row count');
});

export const fetchSecondaryData =
  (
    dataPanel: DataPanel,
    config: DashboardConfig,
    jobs: JobDefinition[],
    variables: DashboardVariableMap,
    sourceType?: string | null,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const state = getState();
    const requestInfo = state.dashboardLayout.requestInfo;

    let secondaryInstructions: DataPanel[] = [];
    if (sourceType !== undefined) {
      secondaryInstructions = dashboardUtils.getSynchronousSecondaryDataInstructions(
        dataPanel,
        sourceType ?? undefined,
      );
    } else {
      const datasetId = getDataPanelDatasetId(dataPanel);
      const dataset = config.datasets[datasetId];
      if (!dataset) return;

      secondaryInstructions = dashboardUtils.getAsynchronousSecondaryDataInstructions(
        dataPanel,
        dataset,
      );
    }
    if (secondaryInstructions.length === 0) return;

    const drilldownDatasetFilters = state.drilldowns.drilldownDatasetFilters;
    const prepareConfig = (instructions: DataPanel) =>
      prepareDataPanelForFetchWrapper(
        instructions,
        config,
        variables,
        state.dashboardData.datasetData,
        drilldownDatasetFilters,
        true,
        RD.getOrDefault(state.embedDashboard.dashboard, undefined),
      );

    secondaryInstructions.forEach((instructions) => {
      const postData: FetchSecondaryDataBody = {
        config: prepareConfig(instructions),
        id: dataPanel.id,
        variables,
        is_secondary_data_query: true,
        is_box_plot:
          instructions.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
        ...attachDatasetToPostData(dataPanel, config.datasets, requestInfo),
      };
      if (requestInfo.useJobQueue) {
        jobs.push({ job_type: ACTION.FETCH_SECONDARY_DATA, job_args: postData });
      } else {
        dispatch(fetchSecondaryDataThunk(postData));
      }
    });
  };

export const fetchSecondaryDataThunk = createAsyncThunk<
  FetchDataPanelData,
  FetchSecondaryDataBody,
  { state: DashboardStates }
>(ACTION.FETCH_SECONDARY_DATA, async (args, { getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/get_data/`,
    appUrl: 'data_panel_templates/get_data/',
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error fetching secondary data');
});

type FetchDrilldownDataBody = {
  dataPanelId: string;
  categoryColumn?: CategoryChartColumnInfo;
  category?: string | number;
  subCategoryColumn?: CategoryChartColumnInfo;
  subCategory?: string | number;
  excludedCategories?: (string | number)[];
};

/*
 * This thunk is called to fetch data for drilldown modal when opened
 */
export const fetchDrilldownDataThunk =
  ({
    dataPanelId,
    categoryColumn,
    category,
    subCategory,
    subCategoryColumn,
    excludedCategories,
  }: FetchDrilldownDataBody): DashboardDataThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = getDashboardConfig(state);
    const { variables, datasetData } = state.dashboardData;
    const dataPanel = config?.dataPanels[dataPanelId];
    if (!config || !variables || !dataPanel) return;

    const drilldownDpt = cloneDeep(dataPanel);
    const isKpiTrend = KPI_NUMBER_TREND_OPERATION_TYPES.has(
      drilldownDpt.visualize_op.operation_type,
    );
    drilldownDpt.visualize_op.operation_type = OPERATION_TYPES.VISUALIZE_TABLE;
    const filterClauses =
      categoryColumn &&
      dpConfigUtils.constructFilterFromDrilldownColumn(
        categoryColumn,
        category,
        subCategoryColumn,
        subCategory,
        excludedCategories,
      );
    const adHocInstructions: AdHocOperationInstructions = {
      filterInfo: {
        filterClauses: filterClauses ?? [],
        matchOnAll: true,
      },
    };

    const drilldownDatasetFilters = state.drilldowns.drilldownDatasetFilters;
    // get the right filter op
    const newDP = prepareDataPanelForFetchWrapper(
      dataPanel,
      config,
      variables,
      datasetData,
      drilldownDatasetFilters,
      false,
      RD.getOrDefault(state.embedDashboard.dashboard, undefined),
    );

    drilldownDpt.filter_op = cloneDeep(newDP.filter_op);

    // Convert period column filter to get correct underlying data for KPI Trend chart
    if (isKpiTrend) {
      const preparedPeriodCol = newDP.visualize_op.instructions.V2_KPI_TREND?.periodColumn;

      if (preparedPeriodCol) {
        const { startDate, endDate } = getDateBetweenFilterValues(preparedPeriodCol);

        drilldownDpt.filter_op?.instructions.filterClauses.push({
          filterColumn: {
            name: preparedPeriodCol.column.name || '',
            friendly_name: preparedPeriodCol.column.friendly_name,
            type: preparedPeriodCol.column.type || 'DATE',
          },
          filterValue: { startDate: startDate.toISO(), endDate: endDate.toISO() },
          filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
        });
      }
    }

    attachLinkFiltersToDp(drilldownDpt, config.datasets, config.elements, variables);

    if (!drilldownDpt.visualize_op.generalFormatOptions)
      drilldownDpt.visualize_op.generalFormatOptions = {};
    if (!drilldownDpt.visualize_op.generalFormatOptions.export)
      drilldownDpt.visualize_op.generalFormatOptions.export = {};
    drilldownDpt.visualize_op.generalFormatOptions.export.disablePdfDownload = true;

    dispatch(updateUnderlyingDataDataPanel({ dataPanel: drilldownDpt, adHocInstructions }));
    dispatch(fetchDataPanelsThunk([drilldownDpt], config));
  };

const prepareDataPanelForFetchWrapper = (
  dp: DataPanel,
  config: DashboardConfig,
  variables: DashboardVariableMap,
  datasetData: DatasetDataObject,
  drilldownDatasetFilters: Record<string, DrilldownDatasetFilter>,
  isSecondaryDataRequest?: boolean,
  dashboard?: EmbedDashboard,
) =>
  dashboardUtils.prepareDataPanelForFetch(
    variables,
    dp,
    config.datasets,
    datasetData,
    config.elements,
    config.dataPanels,
    drilldownDatasetFilters,
    isSecondaryDataRequest,
    getDashboardTimezone(dashboard),
  );
