export enum ROUTES {
  HOME = '/',
  LOGIN = '/login',
  SAML_SIGN_IN = '/saml',
  SAML_INITIATE_AUTH = '/saml-login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:resetToken',
  SIGN_UP = '/signup',
  CHANGELOG = '/changelog',
  USE_CASES = '/use-cases/:useCaseId',
  INTEGRATIONS = '/integrations/:integrationId',
  CHANGELOG_ENTRY = '/changelog/:entryUrlId',
  CHECK_YOUR_EMAIL = '/check-your-email',
  VERIFY_EMAIL = '/verify-email/:verificationId',
  SETUP = '/setup',
  HOME_APP_PAGE = '/home',
  HOME_APP_PAGE_FOLDER = '/home/:folderId',
  JOIN_TEAM = '/join-team',
  TEAM = '/team',
  TRIAL_EXPIRED = '/trial-expired',
  CONNECT_DATA_SOURCE = '/connect',
  SWAP_DATA_SOURCE = '/swap-datasource',
  SYNC_DATA_TABLES = '/sync-tables/:passedSchemaId',
  SYNC_DATA_TABLES_NO_SCHEMA = '/sync-tables',
  DASHBOARD_PAGE = '/dashboard/:dashboardId',
  DASHBOARD_PREVIEW_PAGE = '/preview/:embedId',
  DATA_PAGE = '/datasources',
  MANAGE_DATA_TABLES = '/datasources/:dataSourceId',
  SETTINGS_PAGE = '/settings/:subsectionId',
  SETTINGS_ACCESS_GROUPS = '/settings/api-tokens',
  BILLING_PAGE = '/settings/billing',
  GLOBAL_CUSTOM_STYLES_PAGE = '/styles',
  CUSTOMERS_PAGE = '/customers',
  SHARED_DASHBOARD = '/share/:shareId',
  END_USER_PORTAL = '/portal/:token?',
  SHARED_DASHBOARD_STRICT = '/share/:shareId/strict',
  SHARE_CHART = '/share/:shareId/chart/:dataPanelId',
  PDF_DASHBOARD = '/pdf/:shareId',
  EMAIL_DASHBOARD = '/email/:shareId',
  IFRAME_CHART = '/iframe/chart/:embedJwt',
  IFRAME = '/iframe/:firstParam/:secondParam?/:thirdParam?',
  NOTION_ANALYTICS = '/notion-analytics/:pageName',
  EMAIL_FORM_SUBMISSION_REDIRECT = '/email-form-submission/:source/:email',
  ANALYTICS = '/analytics',
  SUPERUSER = '/superuser',
  REPORT_BUILDER = '/report-builder',
  REPORT_BUILDER_FOLDER = '/report-builder/:folderId',
  REPORT_BUILDER_EDITOR = '/report-builder/:reportBuilderId/:view',
  REPORT_BUILDER_EXPORT = '/report-builder/export/:reportBuilderEmbedId/:customerToken?',
  REPORT_BUILDER_IFRAME = '/report-builder/iframe/:firstParam/:secondParam?/:thirdParam?',
  UNSUBSCRIBE_REPORT_BUILDER_EMAIL = '/report-builder/unsubscribe/:emailId/:email',
  EMBED_REFERENCE = '/embed_id/:embedId',
  DATA_LIBRARY = '/data-library',
  DATA_LIBRARY_FOLDER = '/data-library/folder/:folderId',
}

// TODO(zifanxiang): Add more route url providers as needed.
export const ROUTE_PROVIDERS = {
  DASHBOARD: (dashboardId: string) => `/dashboard/${dashboardId}`,
  REPORT_BUILDER: (reportBuilderId: string, view: string) =>
    `/report-builder/${reportBuilderId}/${view}`,
};
