import { UpdateDefaultTimezoneBody } from 'actions/dashboardActions';
import { Timezones } from 'constants/dashboardConstants';
import { Breadcrumb } from 'types/exploResource';

import { defineAPIAction, defineAPIPostAction } from './actionUtils';
import { ReportBuilderVersion } from './reportBuilderVersionActions';
import { ACTION } from './types';

export interface ReportBuilder extends EmbedReportBuilder {
  team_id: number;
  embed_id: string;
  latest_versions: {
    is_draft: boolean;
    version_number: number;
    modified: string;
  }[];
  entry_id: number;
}

export interface FetchReportBuilderResponse {
  report_builder: ReportBuilder;
  breadcrumbs: Breadcrumb[]; // inclusive of root folder to current folder context
  report_builder_version: ReportBuilderVersion;
}

export interface EmbedReportBuilder {
  id: number;
  embed_id?: string;
  name: string;
  default_timezone: Timezones | null;
}

export const { actionFn: fetchReportBuilder, ...fetchReportBuilderActions } =
  defineAPIAction<FetchReportBuilderResponse>(
    ACTION.FETCH_REPORT_BUILDER,
    'report_builder',
    'get_report_builder',
    'GET',
  );

export const { actionFn: createReportBuilder, successAction: createReportBuilderSuccess } =
  defineAPIPostAction<{ name: string; parent_id: number }, { report_builder: ReportBuilder }>(
    ACTION.CREATE_REPORT_BUILDER,
    'report_builder',
    'create_report_builder',
    'POST',
  );

export const { actionFn: deleteReportBuilder, successAction: deleteReportBuilderSuccess } =
  defineAPIAction(ACTION.DELETE_REPORT_BUILDER, 'report_builder', '', 'DELETE');

export const { actionFn: renameReportBuilder, successAction: renameReportBuilderSuccess } =
  defineAPIPostAction<{ name: string }, { name: string }>(
    ACTION.RENAME_REPORT_BUILDER,
    'report_builder',
    'rename',
    'POST',
  );

export const { actionFn: getReportsUsingDataset } = defineAPIPostAction<
  { dataset_id: string },
  { report_count: number }
>(ACTION.GET_REPORTS_USING_DATASET, 'report_builder', 'get_dataset_usage_count', 'POST');

export const {
  actionFn: updateReportDefaultTimezone,
  successAction: updateReportDefaultTimezoneSuccess,
} = defineAPIPostAction<UpdateDefaultTimezoneBody, {}>(
  ACTION.UPDATE_REPORT_BUILDER_DEFAULT_TIMEZONE,
  'report_builder',
  'update_default_timezone',
  'POST',
);
