// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deExportOptions from 'locales/de/ExportOptions.json';
import enDrilldownChartMenu from 'locales/en/DrilldownChartMenu.json';
import enExportOptions from 'locales/en/ExportOptions.json';
import enWebShareOptions from 'locales/en/WebShareOption.json';
import jaDrilldownChartMenu from 'locales/ja/DrilldownChartMenu.json';
import jaExportOptions from 'locales/ja/ExportOptions.json';
import jaWebShareOptions from 'locales/ja/WebShareOption.json';
import { getEnvironment } from 'utils/environmentUtils';

/**
 * Note to devs
 *
 * This actually loads the translations in the app. Above, we should import all jsons for all languages.
 * The namespaces consts should contain the logical name for each json (namespace), and then we have to make sure
 * to update both i18next.d.ts (for typing) and the resources dict below when adding new translations
 */

export const namespaces = ['ExportOptions', 'DrilldownChartMenu', 'WebShareOptions'];

i18n.use(initReactI18next).init({
  debug: getEnvironment() !== 'production',
  lng: 'en',
  ns: namespaces,
  defaultNS: namespaces,
  resources: {
    en: {
      ExportOptions: enExportOptions,
      DrilldownChartMenu: enDrilldownChartMenu,
      WebShareOptions: enWebShareOptions,
    },
    de: {
      ExportOptions: deExportOptions,
    },
    ja: {
      ExportOptions: jaExportOptions,
      DrilldownChartMenu: jaDrilldownChartMenu,
      WebShareOptions: jaWebShareOptions,
    },
  },
  fallbackLng: 'en',
});

export default i18n;
