import { DateTime } from 'luxon';

import { MONTH_SUFFIX, Timezones, YEAR_SUFFIX } from 'constants/dashboardConstants';
import { FilterClause, OPERATION_TYPES } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { FilterOperator } from 'types/filterOperations';

export const getCurrentDate = (timezone?: string): DateTime => {
  return DateTime.now().setZone(timezone ?? Timezones.UTC);
};

export const getSelectedMonthYear = (
  dpProvidedId: string,
  variables: DashboardVariableMap,
  timezone?: string,
): { month: number; year: number } => {
  const { monthKey, yearKey } = getCalendarHeatmapKeys(dpProvidedId);
  const variableYear = Number(variables?.[yearKey]);
  const variableMonth = Number(variables?.[monthKey]);
  const currentDate = getCurrentDate(timezone);

  return {
    // DateTime uses 1 index for months, but we store a 0-indexed month in the variable
    month: isNaN(variableMonth) ? currentDate.month - 1 : variableMonth,
    year: isNaN(variableYear) ? currentDate.year : variableYear,
  };
};

export const attachMonthFilterToDataPanel = (
  dp: DataPanel,
  variables: DashboardVariableMap,
  timezone?: string,
): void => {
  const { operation_type, instructions } = dp.visualize_op;
  if (operation_type !== OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP) return;

  const dateCol = instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column;
  if (!dateCol?.name || !dateCol.type) return;

  const { month, year } = getSelectedMonthYear(dp.provided_id, variables, timezone);

  const selectedDate = DateTime.fromObject({
    year: year,
    month: month + 1, // DateTime uses 1 index for months
  });

  const dateFilter: FilterClause = {
    filterColumn: { name: dateCol.name, type: dateCol.type },
    filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
    filterValue: {
      startDate: selectedDate.startOf('month').toISO(),
      endDate: selectedDate.endOf('month').toISO(),
    },
  };
  dp.filter_op.instructions.filterClauses.push(dateFilter);
};

export const getCalendarHeatmapKeys = (dpProvidedId: string) => {
  return { monthKey: dpProvidedId + MONTH_SUFFIX, yearKey: dpProvidedId + YEAR_SUFFIX };
};
