import { ComputedView } from '@explo-tech/fido-api';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { CustomerReportAgg } from 'actions/customerReportActions';
import { saveDraftDatasetQuery } from 'actions/datasetActions';
import * as actions from 'actions/reportBuilderActions';
import {
  BuiltInReportConfig,
  CreateReportBuilderDataset,
  ReportBuilderConfig,
  ReportBuilderDataset,
  ReportBuilderDatasetData,
  UpdateBuiltInReport,
  UpdateBuiltInReportConfig,
  UpdateReportBuilderColConfig,
  UpdateReportBuilderColFormatting,
  UpdateReportBuilderDataset,
} from 'actions/reportBuilderConfigActions';
import * as versionActions from 'actions/reportBuilderVersionActions';
import { switchCustomer } from 'actions/teamActions';
import { Jobs } from 'components/JobQueue/types';
import { FLOAT } from 'constants/dataConstants';
import { Aggregation } from 'constants/types';
import { createBlankView } from 'pages/ReportBuilder/utils/viewUtils';
import * as RD from 'remotedata';
import {
  fetchDashboardDatasetError,
  fetchDashboardDatasetRequest,
  fetchDashboardDatasetRowCountSuccess,
  fetchDashboardDatasetSuccess,
} from 'reportBuilderContent/actions/dashboardJobActions';
import { FetchReportBuilderDatasetData } from 'reportBuilderContent/apiTypes';
import {
  fetchAppDatasetRowCount,
  fetchReportBuilderDatasetApi,
} from 'reportBuilderContent/thunks/appDataThunks';
import { ResourcePageType, VersionInfo } from 'types/exploResource';
import { getAggColName } from 'utils/V2ColUtils';
import { saveResourceConfig } from 'utils/customEventUtils';
import { getCurrentISOString } from 'utils/dateUtils';
import {
  getEmbeddoResponseFromFidoResponse,
  getEmbeddoSchemaFromFidoSchema,
} from 'utils/fido/fidoShims';
import { ComputedViewWithIds } from 'utils/fido/fidoUtils';
import { shiftIdsInList } from 'utils/general';
import { titleCase } from 'utils/graphUtils';
import { getDuplicateColumnsFromSchema } from 'utils/queryUtils';
import { cloneDeep } from 'utils/standard';

import { fetchFidoReportBuilderQueryPreview } from './thunks/dashboardDataThunks/fetchFidoDataThunks';
import { createComputedView, getComputedViews, saveComputedView } from './thunks/fidoThunks';
import { createReportBuilderDraft, saveReportBuilderDraft } from './thunks/resourceSaveThunks';
import { revertResourceToVersionThunk } from './thunks/versionManagementThunks';

interface ReportBuilderEditReducerState {
  config: RD.ResponseData<ReportBuilderConfig>;
  versionInfo: VersionInfo | null;
  versionsMetadata: RD.ResponseData<versionActions.ReportBuilderVersionMetadata[]>;
  datasetData: Record<string, ReportBuilderDatasetData>;
  selectedBuiltInId: string | undefined;
  jobs: Record<string, Jobs>; // Job Queue
  currentReportBuilderId: number | null;
}

const initialState: ReportBuilderEditReducerState = {
  config: RD.Idle(),
  versionInfo: null,
  versionsMetadata: RD.Idle(),
  datasetData: {},
  selectedBuiltInId: undefined,
  jobs: {},
  currentReportBuilderId: null,
};

const setVersion = (
  state: ReportBuilderEditReducerState,
  newVersion: versionActions.ReportBuilderVersion,
) => {
  state.config = RD.Success(newVersion.config);

  state.versionInfo = {
    is_draft: newVersion.is_draft,
    version_number: newVersion.version_number,
    edit_version_number: newVersion.edit_version_number,
    change_comments: newVersion.change_comments,
  };
};

const updateConfig = (
  state: ReportBuilderEditReducerState,
  updateFunc: (config: ReportBuilderConfig) => void,
  updateReportBuilder = true,
) => {
  if (!RD.isSuccess(state.config) || !state.currentReportBuilderId) return;
  updateFunc(state.config.data);
  if (updateReportBuilder) saveResourceConfig(state.currentReportBuilderId);
};

const updateDataset = (
  state: ReportBuilderEditReducerState,
  datasetId: string,
  updateFunc: (dataset: ReportBuilderDataset) => void,
) => {
  updateConfig(state, (config) => {
    const dataset = config.datasets[datasetId];
    if (dataset) updateFunc(dataset);
  });
};

const updateBuiltInReportData = (
  state: ReportBuilderEditReducerState,
  builtInId: string,
  updateFunc: (dataset: BuiltInReportConfig) => void,
) => {
  updateConfig(state, (config) => {
    const report = config.builtInReports?.[builtInId];
    if (!report) return;
    // Need to manually track modified since Built Ins are stored as JSON instead of standalone DB models
    report.modified = getCurrentISOString();
    updateFunc(report);
  });
};

function handleFetchReportBuilderDatasetPending(
  state: ReportBuilderEditReducerState,
  datasetId: string,
  page: number | undefined,
) {
  const currentData = state.datasetData[datasetId];
  const rowCount = page === undefined ? undefined : currentData?.rowCount;
  state.datasetData[datasetId] = { loading: true, rowCount: rowCount };
}

function handleFetchReportBuilderDatasetFulfilled(
  payload: FetchReportBuilderDatasetData,
  state: ReportBuilderEditReducerState,
  datasetId: string,
  save: boolean | undefined,
  rowCount?: number,
) {
  const duplicateColumns = getDuplicateColumnsFromSchema(payload.schema);

  state.datasetData[datasetId] = {
    ...state.datasetData[datasetId],
    rows: payload.rows,
    // Don't need friendly_name so removing
    schema: payload.schema.map((col) => ({ name: col.name, type: col.type })),
    unsupportedOperations: payload.unsupported_operations,
    loading: false,
    duplicateColumns,
  };

  if (rowCount) {
    state.datasetData[datasetId].rowCount = rowCount;
  }

  if (!save || duplicateColumns !== null) return;

  updateDataset(state, datasetId, (dataset) => {
    dataset.query = dataset.queryDraft ?? dataset.query;
    dataset.queryDraft = undefined;
    dataset.schema = payload.schema;

    const columnNames = payload.schema.map((col) => col.name);
    const colNameSet = new Set(columnNames);
    const customAggSet = new Set(dataset.customAggregations?.map(getAggColName));

    // Remove column configs that are no longer in schema or custom aggs
    Object.keys(dataset.columnConfigs).forEach((colName) => {
      if (!colNameSet.has(colName) && !customAggSet.has(colName))
        delete dataset.columnConfigs[colName];
    });

    // Add column configs for new columns
    columnNames.forEach((colName) => {
      if (colName in dataset.columnConfigs) return;
      dataset.columnConfigs[colName] = { name: titleCase(colName), isVisible: true };
    });
  });
}

function handleFetchReportBuilderDatasetRejected(
  state: ReportBuilderEditReducerState,
  datasetId: string,
  error: string,
) {
  state.datasetData[datasetId].error = error;
  state.datasetData[datasetId].loading = false;
}

function handleFetchReportBuilderRowCountFulfilled(
  state: ReportBuilderEditReducerState,
  datasetId: string,
  rowCount: number,
) {
  state.datasetData[datasetId].rowCount = rowCount;
}

const reportBuilderEditorSlice = createSlice({
  name: 'reportBuilderEditor',
  initialState,
  reducers: {
    clearReportBuilderVersions: (state) => {
      state.versionsMetadata = RD.Idle();
    },
    createReportBuilderDataset: (state, { payload }: PayloadAction<CreateReportBuilderDataset>) => {
      updateConfig(state, (config) => {
        const newDataset: ReportBuilderDataset = {
          description: '',
          id: payload.id,
          name: payload.name,
          parent_schema_id: payload.parentSchemaId,
          query: '',
          columnConfigs: {},
        };
        config.datasets[payload.id] = newDataset;
      });
    },
    updateReportBuilderDataset: (state, { payload }: PayloadAction<UpdateReportBuilderDataset>) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        if (payload.name !== undefined) dataset.name = payload.name;
        if (payload.description !== undefined) dataset.description = payload.description;
        if (payload.permissions !== undefined) dataset.permissions = payload.permissions;
        if (payload.schemaId !== undefined) dataset.parent_schema_id = payload.schemaId;
      });
    },
    updateReportBuilderColConfig: (
      state,
      { payload: { colName, config, datasetId } }: PayloadAction<UpdateReportBuilderColConfig>,
    ) => {
      updateDataset(state, datasetId, (dataset) => {
        const prev = dataset.columnConfigs[colName];
        dataset.columnConfigs[colName] = { ...prev, ...config };
      });
    },
    updateReportBuilderColFormatting: (
      state,
      { payload }: PayloadAction<UpdateReportBuilderColFormatting>,
    ) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        const colConfig = dataset.columnConfigs[payload.colName];
        if (!colConfig) return;
        colConfig.displayFormatting = payload.formatting;
      });
    },
    addReportBuilderCustomAggregation: (
      state,
      { payload }: PayloadAction<{ datasetId: string }>,
    ) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        const newAgg: CustomerReportAgg = {
          agg: { formula: '', id: Aggregation.FORMULA },
          column: { name: uuidv4(), type: FLOAT },
        };
        if (!dataset.customAggregations) dataset.customAggregations = [newAgg];
        else dataset.customAggregations.push(newAgg);
      });
    },
    deleteReportBuilderCustomAggregation: (
      state,
      { payload }: PayloadAction<{ datasetId: string; aggId: string }>,
    ) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        if (!dataset.customAggregations) dataset.customAggregations = [];
        const index = dataset.customAggregations.findIndex(
          (agg) => agg.column.name === payload.aggId,
        );
        dataset.customAggregations.splice(index, 1);
        delete dataset.columnConfigs[payload.aggId];
      });
    },
    updateReportBuilderCustomAggregation: (
      state,
      {
        payload,
      }: PayloadAction<{
        datasetId: string;
        aggId: string;
        agg: Partial<Omit<CustomerReportAgg, 'id'>>;
      }>,
    ) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        if (!dataset.customAggregations) dataset.customAggregations = [];

        const index = dataset.customAggregations.findIndex(
          (agg) => agg.column.name === payload.aggId,
        );
        if (index === -1) return;

        dataset.customAggregations[index] = {
          ...dataset.customAggregations[index],
          ...payload.agg,
        };
      });
    },
    deleteReportBuilderDataset: (state, { payload }: PayloadAction<string>) => {
      updateConfig(state, (config) => {
        if (payload in config.datasets) delete config.datasets[payload];
      });
    },
    createBuiltInReport: (state) => {
      updateConfig(state, (config) => {
        const now = getCurrentISOString();
        const newBuiltIn: BuiltInReportConfig = {
          id: uuidv4(),
          name: 'New Report',
          description: '',
          config: { views: [createBlankView()] },
          permissions: { allCustomers: true, customerIds: [], groupTagIds: [] },
          // Need to manually track created and modified since Built Ins are stored as JSON instead of standalone DB models
          created: now,
          modified: now,
        };

        if (!config.builtInReports) config.builtInReports = {};
        if (!config.builtInOrder) config.builtInOrder = Object.keys(config.builtInReports);
        config.builtInOrder.push(newBuiltIn.id);
        config.builtInReports[newBuiltIn.id] = newBuiltIn;
        state.selectedBuiltInId = newBuiltIn.id;
      });
    },
    duplicateBuiltInReport: (state, { payload }: PayloadAction<BuiltInReportConfig>) => {
      updateConfig(state, (config) => {
        const now = getCurrentISOString();
        const newBuiltIn = cloneDeep(payload);
        newBuiltIn.id = uuidv4();
        newBuiltIn.name = `${newBuiltIn.name} Copy`;
        // Change ids so no clashing when saving table data
        newBuiltIn.config.views?.forEach((view) => (view.id = uuidv4()));
        newBuiltIn.created = now;
        newBuiltIn.modified = now;

        if (!config.builtInReports) config.builtInReports = {};
        config.builtInReports[newBuiltIn.id] = newBuiltIn;
        config.builtInOrder = [...(config.builtInOrder || []), newBuiltIn.id];
        state.selectedBuiltInId = newBuiltIn.id;
      });
    },
    updateBuiltInReportSettings: (state, { payload }: PayloadAction<UpdateBuiltInReport>) => {
      updateBuiltInReportData(state, payload.builtInId, (report) => {
        if (payload.name) report.name = payload.name;
        if (payload.description !== undefined) report.description = payload.description;
        if (payload.permissions) report.permissions = payload.permissions;
      });
    },
    updateBuiltInReportConfig: (state, { payload }: PayloadAction<UpdateBuiltInReportConfig>) => {
      updateBuiltInReportData(state, payload.builtInId, (report) => {
        report.config = payload.config;
      });
    },
    deleteBuiltInReport: (state, { payload: builtInId }: PayloadAction<string>) => {
      updateConfig(state, (config) => {
        const builtInReports = config.builtInReports;
        if (builtInReports) delete builtInReports[builtInId];

        const orderIndex = config.builtInOrder?.indexOf(builtInId);
        if (config.builtInOrder && orderIndex != null) config.builtInOrder.splice(orderIndex, 1);

        if (state.selectedBuiltInId === builtInId)
          state.selectedBuiltInId = Object.keys(builtInReports || {})[0];
      });
    },
    reorderBuiltIns: (
      state,
      {
        payload: { activeId, overId },
      }: PayloadAction<{
        activeId: string;
        overId: string;
      }>,
    ) => {
      updateConfig(state, (config) => {
        if (!config.builtInOrder) config.builtInOrder = Object.keys(config.builtInReports || {});
        shiftIdsInList(config.builtInOrder, overId, activeId, (id) => id);
      });
    },
    selectBuiltInReport: (state, { payload: builtInId }: PayloadAction<string | undefined>) => {
      state.selectedBuiltInId = builtInId;
    },
    clearDuplicateColumns: (state, { payload: datasetId }: PayloadAction<string>) => {
      const datasetData = state.datasetData[datasetId];
      if (!datasetData) return;
      datasetData.duplicateColumns = null;
    },
    setShowTotals: (state, { payload }: PayloadAction<boolean>) =>
      updateConfig(state, (config) => {
        if (!config.general) config.general = {};
        config.general.showTotals = payload;
      }),
    setTotalsOnByDefault: (state, { payload }: PayloadAction<boolean>) =>
      updateConfig(state, (config) => {
        if (!config.general) config.general = {};
        config.general.totalsOnByDefault = payload;
      }),
    clearReportBuilderReducer: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(versionActions.fetchReportBuilderVersionsMetadataSuccess, (state, { payload }) => {
        state.versionsMetadata = RD.Success(payload.versions_metadata);
      })
      .addCase(versionActions.fetchReportBuilderVersionsMetadataRequest, (state) => {
        state.versionsMetadata = RD.Loading();
      })
      .addCase(versionActions.fetchReportBuilderVersionsMetadataError, (state) => {
        state.versionsMetadata = RD.Error('Error loading report builder versions');
      })
      .addCase(versionActions.publishNewReportBuilderVersionSuccess, (state, { payload }) => {
        const version = payload.version;

        state.versionInfo = {
          is_draft: version.is_draft,
          version_number: version.version_number,
          edit_version_number: version.edit_version_number,
          change_comments: version.change_comments,
        };

        RD.update(state.versionsMetadata, (versionsMetadata) => {
          const publishedVersion = versionsMetadata.find(({ id }) => id === version.id);
          if (publishedVersion) {
            publishedVersion.is_draft = payload.version.is_draft;
            publishedVersion.published_by_id = payload.version.published_by_id;
            publishedVersion.change_comments = payload.version.change_comments;
            publishedVersion.version_saved_at = payload.version.version_saved_at;
          }
        });
      })
      .addCase(versionActions.deleteCurrentReportBuilderDraftSuccess, (state, { payload }) => {
        RD.update(state.versionsMetadata, (versionsMetadata) => versionsMetadata.splice(0, 1));
        setVersion(state, payload.previous_version);
      })
      .addCase(revertResourceToVersionThunk.fulfilled, (state, { payload, meta }) => {
        const { new_version } = payload;

        if (meta.arg.isExplore || !('config' in new_version)) return;

        RD.update(state.versionsMetadata, (versionsMetadata) => {
          const index = versionsMetadata.findIndex(
            ({ version_number }) => version_number === new_version.version_number,
          );

          // Remove an active draft if present, since we are pushing a new draft based on the revert
          if (index >= 0) versionsMetadata.splice(index, 1);

          versionsMetadata.unshift(new_version);
        });

        setVersion(state, new_version);
      })
      .addCase(saveDraftDatasetQuery, (state, { payload }) => {
        updateDataset(state, payload.dataset_id, (dataset) => {
          dataset.queryDraft =
            payload.queryDraft === dataset.query ? undefined : payload.queryDraft;
        });
      })
      .addCase(saveReportBuilderDraft.fulfilled, (state, { payload }) => {
        if ('edit_version_number' in payload) {
          if (state.versionInfo) {
            state.versionInfo.edit_version_number = payload.edit_version_number;
          }

          RD.update(state.versionsMetadata, (versionsMetadata) => {
            versionsMetadata[0].edit_version_number = payload.edit_version_number;
          });
        } else {
          const newVersion = payload.new_version;
          setVersion(state, newVersion);

          RD.update(state.versionsMetadata, (versionsMetadata) =>
            versionsMetadata.unshift(newVersion),
          );
        }
      })
      .addCase(createReportBuilderDraft.fulfilled, (state, { payload }) => {
        const { new_version } = payload;
        setVersion(state, new_version);

        RD.update(state.versionsMetadata, (versionsMetadata) =>
          versionsMetadata.unshift(new_version),
        );
      })
      .addCase(switchCustomer, (state) => {
        state.datasetData = {};
      })
      .addCase(fetchReportBuilderDatasetApi.pending, (state, { meta }) => {
        handleFetchReportBuilderDatasetPending(state, meta.arg.datasetId, meta.arg.page);
      })
      .addCase(fetchReportBuilderDatasetApi.fulfilled, (state, { meta, payload }) => {
        const { datasetId, save } = meta.arg;
        handleFetchReportBuilderDatasetFulfilled(payload, state, datasetId, save);
      })
      .addCase(fetchReportBuilderDatasetApi.rejected, (state, { meta, error }) => {
        const datasetId = meta.arg.datasetId;
        handleFetchReportBuilderDatasetRejected(
          state,
          datasetId,
          error.message ?? 'Something went wrong',
        );
      })
      .addCase(fetchAppDatasetRowCount.fulfilled, (state, { meta, payload }) => {
        const datasetId = meta.arg.datasetId;
        const rowCount = payload.row_count;
        handleFetchReportBuilderRowCountFulfilled(state, datasetId, rowCount);
      })
      // Job Queue
      .addCase(fetchDashboardDatasetRequest, (state, { payload: { postData } }) => {
        handleFetchReportBuilderDatasetPending(state, postData.datasetId, postData.page);
      })
      .addCase(fetchDashboardDatasetSuccess, (state, { payload }) => {
        const { datasetId, save } = payload.jobArgs;
        handleFetchReportBuilderDatasetFulfilled(payload, state, datasetId, save);
      })
      .addCase(fetchDashboardDatasetError, (state, { payload }) => {
        handleFetchReportBuilderDatasetRejected(
          state,
          payload.job.jobArgs.datasetId,
          payload.error,
        );
      })
      .addCase(fetchDashboardDatasetRowCountSuccess, (state, { payload }) => {
        handleFetchReportBuilderRowCountFulfilled(
          state,
          payload.jobArgs.datasetId,
          payload.row_count,
        );
      })
      // End of Job Queue
      // FIDO
      .addCase(getComputedViews.fulfilled, (state, { payload }) => {
        updateConfig(state, (config) => {
          payload.views.forEach((v) => {
            const dataset = getDatasetFromFidoId(
              config.datasets,
              (v.view as ComputedViewWithIds).id,
            );
            if (!dataset) return;

            const view = v.view as ComputedView;

            dataset.name = view.name;
            dataset.description = view.description ?? '';
            dataset.query = view.query;
            dataset.schema = getEmbeddoSchemaFromFidoSchema(view.columnDefinitions);
          });
        });
      })
      .addCase(createComputedView.fulfilled, (state, { payload, meta }) => {
        if (meta.arg.resourceType !== ResourcePageType.REPORT_BUILDER) return;
        if (!state.config) return;

        const id = meta.arg.datasetId ?? uuidv4();

        updateConfig(state, (config) => {
          const newDataset: ReportBuilderDataset = {
            id: id,
            fido_id: payload.view.id ?? undefined,
            name: payload.view.name,
            description: payload.view.description ?? '',
            parent_schema_id: meta.arg.namespace?.id ?? -1,
            columnConfigs: {},
            query: '',
            namespace_id: payload.view.namespaceId ?? undefined,
          };
          config.datasets[id] = newDataset;
        });
      })
      .addCase(saveComputedView.fulfilled, (state, { meta }) => {
        if (!state.config) return;

        updateConfig(state, (config) => {
          const dataset = getDatasetFromFidoId(config.datasets, meta.arg.id);
          if (!dataset) return;

          dataset.name = meta.arg.name;
          dataset.description = meta.arg.description ?? '';
          dataset.permissions = meta.arg.permissions;
          dataset.namespace_id = meta.arg.namespaceId;
        });
      })
      .addCase(fetchFidoReportBuilderQueryPreview.pending, (state, { meta }) => {
        handleFetchReportBuilderDatasetPending(
          state,
          meta.arg.dataset.id,
          meta.arg.body.dataRequestParameters.pagingConfiguration?.page,
        );
      })
      .addCase(fetchFidoReportBuilderQueryPreview.fulfilled, (state, { payload, meta }) => {
        const { dataset, save } = meta.arg;

        const { schema, totalResults, rows } = getEmbeddoResponseFromFidoResponse(
          payload,
          meta.arg.timezone,
        );

        handleFetchReportBuilderDatasetFulfilled(
          { rows, schema, unsupported_operations: [], source_type: null },
          state,
          dataset.id,
          save,
          totalResults ?? undefined,
        );
      })
      .addCase(fetchFidoReportBuilderQueryPreview.rejected, (state, { meta, error }) => {
        const { dataset } = meta.arg;

        handleFetchReportBuilderDatasetRejected(
          state,
          dataset.id,
          error.message ?? 'Something went wrong',
        );
      })
      .addCase(actions.fetchReportBuilderActions.successAction, (state, { payload }) => {
        state.currentReportBuilderId = payload.report_builder.id;
        const version = payload.report_builder_version;
        state.config = RD.Success(version.config);
        state.selectedBuiltInId = Object.keys(state.config.data.builtInReports || {})[0];
        state.versionInfo = {
          is_draft: version.is_draft,
          version_number: version.version_number,
          edit_version_number: version.edit_version_number,
          change_comments: version.change_comments,
        };
      })
      .addCase(actions.fetchReportBuilderActions.requestAction, (state) => {
        state.config = RD.Loading();
      })
      .addCase(actions.fetchReportBuilderActions.errorAction, (state) => {
        state.config = RD.Error('Error Loading Report Builder Version');
      });
    //End of Fido
  },
});

export const getReportBuilderConfig = createSelector(
  (state: ReportBuilderEditReducerState) => state.config,
  (config) => {
    if (!RD.isSuccess(config)) return;
    return config.data;
  },
);

export const getSelectedBuiltIn = createSelector(
  (state: ReportBuilderEditReducerState) => state.config,
  (state: ReportBuilderEditReducerState) => state.selectedBuiltInId,
  (config, selectedBuiltInId) => {
    if (!RD.isSuccess(config)) return;
    return selectedBuiltInId ? config.data.builtInReports?.[selectedBuiltInId] : undefined;
  },
);

export const getOrderedBuiltIns = createSelector(
  (state: ReportBuilderEditReducerState) => state.config,
  (config) => {
    if (!RD.isSuccess(config)) return [];
    const { builtInOrder, builtInReports } = config.data;
    if (!builtInOrder || !builtInReports) return Object.values(builtInReports || {});
    return builtInOrder.map((id) => builtInReports[id]).filter((report) => !!report);
  },
);

export default reportBuilderEditorSlice.reducer;

export const {
  clearReportBuilderVersions,
  createReportBuilderDataset,
  updateReportBuilderDataset,
  deleteReportBuilderDataset,
  clearReportBuilderReducer,
  updateReportBuilderColConfig,
  updateReportBuilderColFormatting,
  addReportBuilderCustomAggregation,
  deleteReportBuilderCustomAggregation,
  updateReportBuilderCustomAggregation,
  createBuiltInReport,
  duplicateBuiltInReport,
  updateBuiltInReportSettings,
  updateBuiltInReportConfig,
  deleteBuiltInReport,
  selectBuiltInReport,
  clearDuplicateColumns,
  reorderBuiltIns,
  setShowTotals,
  setTotalsOnByDefault,
} = reportBuilderEditorSlice.actions;

const getDatasetFromFidoId = (
  datasets: Record<string, ReportBuilderDataset> | undefined,
  fidoId: string,
) => Object.values(datasets ?? {}).find((dataset) => dataset.fido_id === fidoId);
