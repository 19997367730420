import { DatepickerElemConfig } from 'types/dashboardTypes';
import { DATE_RANGE_TYPES } from 'types/dateRangeTypes';
import { getDateMax, getDateMin } from 'utils/dateUtils';

export function getConfigDateLimits(config: DatepickerElemConfig, timezone: string) {
  return config.dateRangeType === DATE_RANGE_TYPES.EXACT
    ? {
        minDate: config.minValue,
        maxDate: config.maxValue,
      }
    : {
        minDate: getDateMin(config.relativeDateRange, timezone),
        maxDate: getDateMax(config.relativeDateRange, timezone),
      };
}

export const getDatePickerInputFromString = (
  value: string | undefined,
  isRange: boolean | undefined,
): Date | [Date, Date] | null => {
  if (!value) return null;

  // this is only true if the user is using a date picker component and happens to
  // type the input instead of selecting it. This is pretty poorly handled, so if
  // they type any string other than, for example, 1/1/2024, it just silently fails...
  if (isRange) {
    const [date1, date2] = value.split('-');
    const date1Parsed = new Date(date1);
    const date2Parsed = new Date(date2);
    if (isNaN(date1Parsed.getTime()) || isNaN(date2Parsed.getTime())) return null;

    return [date1Parsed, date2Parsed];
  } else {
    const date = new Date(value);
    return isNaN(date.getTime()) ? null : date;
  }
};
