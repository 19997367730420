import { DateTime } from 'luxon';

import { User } from 'actions/userActions';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { dateTimeFromISOString } from 'utils/dateUtils';

export const numDaysLeftInPlan = (trialEndDateString?: string | null) => {
  if (!trialEndDateString) return 0;
  const trialEndDate = dateTimeFromISOString(trialEndDateString);
  const today = DateTime.now();
  const daysUntilTrialEnds = trialEndDate.diff(today, 'days').toObject().days || 0;
  // Ceiling because if a full day isn't completed yet, count that day fully
  return Math.ceil(daysUntilTrialEnds > 0 ? daysUntilTrialEnds : 0);
};

export type PaymentPlan = PLAN_TYPES | undefined;

export const isEmailReportingEnabled = (user: User) =>
  user.team?.entitlements.enable_dashboard_emails ||
  user.team?.payment_plan == PLAN_TYPES.ENTERPRISE;

export const isReportBuilderEnabled = (user: User) =>
  !!user.team?.entitlements.product_report_builder_enabled;

export const showExploBranding = (plan: PaymentPlan) => {
  switch (plan) {
    case PLAN_TYPES.ENTERPRISE:
    case PLAN_TYPES.PRO:
      return false;
    default:
      return true;
  }
};

export const isCreateCustomersDisabled = (plan: PaymentPlan) => {
  switch (plan) {
    case PLAN_TYPES.ENTERPRISE:
    case PLAN_TYPES.PRO:
    case PLAN_TYPES.GROWTH_V2:
      return false;
    case PLAN_TYPES.LAUNCH:
      return true;
    default:
      return true;
  }
};

export const isEmbeddingDisabled = (plan: PaymentPlan) => {
  switch (plan) {
    case PLAN_TYPES.ENTERPRISE:
    case PLAN_TYPES.PRO:
    case PLAN_TYPES.GROWTH_V2:
      return false;
    default:
      return true;
  }
};
