import { createSlice } from '@reduxjs/toolkit';

import { DashboardVariable } from 'types/dashboardTypes';
import { FilterOperator } from 'types/filterOperations';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface DrilldownSourceInfo {
  sourceDashboardId: number;
  sourceDataPanelId: string;
  drilldownEntryPointId: string;
}

export interface DrilldownFilterInfo {
  operatorId: FilterOperator;
  filterValue: DashboardVariable;
}

// A drilldown dataset filter is a map of the dataset column to an array of drilldown filter info.
// An array of drilldown filter infos is used as there can be multiple filters for a single column
// in the case that the filter value is an expanded value such as a date range.
export type DrilldownDatasetFilter = Record<string, DrilldownFilterInfo[]>;

interface DrilldownsState {
  currentSourceInfos: DrilldownSourceInfo[];
  // A map of dataset id to dataset column to drilldown filter info (which contains an array of
  // drilldown filter operators and values).
  // The drilldown dataset filters will be used to automatically generated filter clauses for all
  // data against the specified dataset.
  drilldownDatasetFilters: Record<string, DrilldownDatasetFilter>;
}

const initialState: DrilldownsState = {
  currentSourceInfos: [],
  drilldownDatasetFilters: {},
};

const drilldownsReducer = createSlice({
  name: 'drilldowns',
  initialState,
  reducers: {
    clearDrilldownsState: (state) => {
      state.currentSourceInfos = [];
      state.drilldownDatasetFilters = {};
    },
    setCurrentSourceInfos: (state, action: PayloadAction<DrilldownSourceInfo[]>) => {
      state.currentSourceInfos = action.payload;
    },
    setDrilldownFilters: (state, action: PayloadAction<Record<string, DrilldownDatasetFilter>>) => {
      state.drilldownDatasetFilters = action.payload;
    },
  },
});

export const { clearDrilldownsState, setCurrentSourceInfos, setDrilldownFilters } =
  drilldownsReducer.actions;
export default drilldownsReducer.reducer;
