import { PayloadAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

import { fetchDashboardSuccess } from 'actions/dashboardActions';
import { UpdateVisualizeOperationColorPayload } from 'actions/dashboardStylesActions';
import { fetchDataPanelRequest, fetchDataPanelSuccess } from 'actions/dataPanelTemplateAction';
import {
  embedFetchDashboardActions,
  embedFetchDataPanelRequest,
  embedFetchDataPanelSuccess,
} from 'actions/embedActions';
import {
  deleteAdditionalStyleSuccess,
  getFontConfigActions,
  saveAdditionalStyleSuccess,
  saveFontConfigActions,
  saveGlobalStylesSuccess,
} from 'actions/styleConfigActions';
import { logInUserSuccess } from 'actions/userActions';
import {
  COLOR_SYNC_CHART_TYPES,
  ColorCategoryTracker,
  OPERATION_TYPES,
  VisualizeOperationInstructions,
} from 'constants/types';
import { DEFAULT_GLOBAL_STYLE_CONFIG, getBaseGlobalStyles } from 'globalStyles';
import { DEFAULT_TOOLTIP_BACKGROUND_COLOR } from 'globalStyles/constants';
import { GlobalFontConfig, GlobalStyleConfig } from 'globalStyles/types';
import { fetchPrimaryDataThunk } from 'reducers/thunks/dashboardDataThunks/fetchDataPanelThunks';
import { DataPanelResponse } from 'types/dataPanelTemplate';
import { setColorCategoryData } from 'utils/colorCategorySyncUtils';
import { getEmbeddoResponseFromFidoResponse } from 'utils/fido/fidoShims';
import { cloneDeep } from 'utils/standard';

import * as RD from '../remotedata';

import { updateDashboardCategoryColors } from './dashboardEditConfigReducer';
import { clearDashboardLayoutReducer } from './dashboardLayoutReducer';
import { fetchFidoComputationData } from './thunks/dashboardDataThunks/fetchFidoDataThunks';

type DataPanelColorInfo = {
  operationType: OPERATION_TYPES;
  operationInstructions: VisualizeOperationInstructions;
};

interface DashboardStylesReducer {
  dashboardTheme: string | null;
  globalStyleConfig: GlobalStyleConfig;
  fontConfig: RD.ResponseData<GlobalFontConfig>;
  additionalStyleConfigOptions: Record<string, GlobalStyleConfig | undefined>;
  colorCategoryTracker: ColorCategoryTracker;
  dashboardCategoryColors: Record<string, string>;
  valuesShareColorsAcrossDashboard: boolean;

  // Store relevant info during request
  // Have here since jobs don't store full post data
  dataPanelIdToColorInfo: Record<string, DataPanelColorInfo | undefined>;
  // We pass a globalStyleConfig to an embedded dashboard when previewing custom styles.
  // This allows us to use this instead of the globalStyleConfig when provided
  customStylesOverwriteConfig: GlobalStyleConfig | null;
}

const initialState: DashboardStylesReducer = {
  dashboardTheme: null,
  globalStyleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
  fontConfig: RD.Idle(),
  additionalStyleConfigOptions: {},
  colorCategoryTracker: {},
  dashboardCategoryColors: {},
  dataPanelIdToColorInfo: {},
  customStylesOverwriteConfig: null,
  valuesShareColorsAcrossDashboard: false,
};

const dashboardStylesSlice = createSlice({
  name: 'dashboardStyles',
  initialState,
  reducers: {
    setDashboardTheme: (state, { payload }: PayloadAction<string | undefined>) => {
      const newDashboardTheme = payload ?? null;
      if (newDashboardTheme === state.dashboardTheme) return;

      state.dashboardTheme = newDashboardTheme;
      state.colorCategoryTracker = {};
    },
    setCustomStylesPageOverwrite: (state, { payload }: PayloadAction<GlobalStyleConfig>) => {
      state.customStylesOverwriteConfig = payload;
    },
    // Update color category tracker when table instructions are updated (e.g. when category coloring is turned on)
    updateVisualizeOperationColor: (
      state,
      {
        payload: { previewData, schema, operationInstructions, operationType, dataPanelId },
      }: UpdateVisualizeOperationColorPayload,
    ) => {
      const globalStyleConfig = getGlobalStyleConfig(state);

      setColorCategoryData({
        colorCategoryTracker: state.colorCategoryTracker,
        dashboardCategoryColors: state.dashboardCategoryColors,
        globalStyleConfig,
        operationType,
        operationInstructions,
        previewData,
        schema,
        dataPanelId,
        shouldReplace: true,
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(clearDashboardLayoutReducer, (state) => {
        state.colorCategoryTracker = {};
        state.dataPanelIdToColorInfo = {};
        state.dashboardCategoryColors = {};
      })
      .addCase(embedFetchDashboardActions.successAction, (state, { payload }) => {
        if (payload.style_config_v2) {
          const sanitizedGlobalStyleConfig = cloneAndSanitizeGlobalStyleConfig(
            payload.style_config_v2,
          );
          state.globalStyleConfig = getBaseGlobalStyles(sanitizedGlobalStyleConfig);
        }

        state.fontConfig = RD.Success(payload.font_config ?? []);

        const sanitizedAdditionalStyleConfigs = Object.fromEntries(
          Object.entries(payload.additional_style_configs).map(([styleConfigId, styleConfig]) => {
            return [styleConfigId, cloneAndSanitizeGlobalStyleConfig(styleConfig)];
          }),
        );
        state.additionalStyleConfigOptions = sanitizedAdditionalStyleConfigs;
        state.dashboardCategoryColors =
          payload.dashboard_version.configuration.category_colors ?? {};
        state.valuesShareColorsAcrossDashboard =
          payload.dashboard_template?.should_values_share_colors_across_dashboard ?? false;
      })
      .addCase(logInUserSuccess, (state, { payload }) => {
        if (!payload.team) return;

        state.fontConfig = RD.Success(payload.team.font_config ?? []);

        if (payload.team.style_config_v2) {
          const sanitizedGlobalStyleConfig = cloneAndSanitizeGlobalStyleConfig(
            payload.team.style_config_v2,
          );
          state.globalStyleConfig = getBaseGlobalStyles(sanitizedGlobalStyleConfig);
        }

        const sanitizedAdditionalStyleConfigs = Object.fromEntries(
          Object.entries(payload.team.additional_style_configs).map(
            ([styleConfigId, styleConfig]) => {
              return [styleConfigId, cloneAndSanitizeGlobalStyleConfig(styleConfig)];
            },
          ),
        );
        state.additionalStyleConfigOptions = sanitizedAdditionalStyleConfigs;
      })
      .addCase(updateDashboardCategoryColors, (state, { payload }) => {
        state.dashboardCategoryColors = payload;
        state.colorCategoryTracker = {};
      })
      .addCase(fetchDashboardSuccess, (state, { payload }) => {
        state.dashboardCategoryColors =
          payload.dashboard_version.configuration.category_colors ?? {};
        state.valuesShareColorsAcrossDashboard =
          payload.dashboard?.should_values_share_colors_across_dashboard ?? false;
      })
      .addCase(saveGlobalStylesSuccess, (state, { payload }) => {
        state.globalStyleConfig = payload.config_v2;
      })
      .addCase(saveAdditionalStyleSuccess, (state, { payload }) => {
        state.additionalStyleConfigOptions[payload.theme_name] = payload.style_config;
      })
      .addCase(deleteAdditionalStyleSuccess, (state, { payload }) => {
        if (!state.additionalStyleConfigOptions[payload.theme_name]) return;
        delete state.additionalStyleConfigOptions[payload.theme_name];
      })
      .addCase(fetchPrimaryDataThunk.pending, (state, { meta }) => {
        const { operation_type, instructions } = meta.arg.postData.config.visualize_op;
        saveDataPanelOperationData(state, meta.arg.postData.id, operation_type, instructions);
      })
      .addCase(fetchPrimaryDataThunk.fulfilled, (state, { meta, payload }) => {
        updateDataPanelColors(state, meta.arg.postData.id, payload.data_panel_template);
      })
      .addCase(fetchFidoComputationData.pending, (state, { meta }) => {
        const { operation_type, instructions } = meta.arg.reducerArgs.visualizeOp;

        if (meta.arg.reducerArgs.isPrimaryRequest) {
          saveDataPanelOperationData(
            state,
            meta.arg.reducerArgs.dataPanelId,
            operation_type,
            instructions,
          );
        }
      })
      .addCase(fetchFidoComputationData.fulfilled, (state, { meta, payload }) => {
        if (meta.arg.reducerArgs.isPrimaryRequest) {
          const { rows, schema } = getEmbeddoResponseFromFidoResponse(
            payload,
            meta.arg.reducerArgs.timezone,
          );
          updateDataPanelColors(state, meta.arg.reducerArgs.dataPanelId, {
            _rows: rows,
            _schema: schema,
          });
        }
      })
      .addMatcher(
        isAnyOf(embedFetchDataPanelRequest, fetchDataPanelRequest),
        (state, { payload }) => {
          const { operation_type, instructions } = payload.postData.config.visualize_op;
          saveDataPanelOperationData(state, payload.postData.id, operation_type, instructions);
        },
      )
      .addMatcher(
        isAnyOf(embedFetchDataPanelSuccess, fetchDataPanelSuccess),
        (state, { payload }) => {
          updateDataPanelColors(state, payload.postData.id, payload.data_panel_template);
        },
      )
      .addMatcher(
        isAnyOf(getFontConfigActions.requestAction, saveFontConfigActions.requestAction),
        (state) => {
          state.fontConfig = RD.Loading();
        },
      )
      .addMatcher(
        isAnyOf(getFontConfigActions.errorAction, saveFontConfigActions.errorAction),
        (state) => {
          state.fontConfig = RD.Error('Error loading font config');
        },
      )
      .addMatcher(
        isAnyOf(getFontConfigActions.successAction, saveFontConfigActions.successAction),
        (state, { payload }) => {
          state.fontConfig = RD.Success(payload.font_config);
        },
      ),
});

// There's a strange Redux bug where when using addCase with a thunk results in a TS error about circular dependencies
// This is true, but it's not a problem for other reducers...
// To get around it, we have to explicitly type the reducer to break the circle
export const dashboardStylesReducer =
  dashboardStylesSlice.reducer as Reducer<DashboardStylesReducer>;

export const { setDashboardTheme, updateVisualizeOperationColor, setCustomStylesPageOverwrite } =
  dashboardStylesSlice.actions;

export const getCurrentTheme = createSelector(
  (state: DashboardStylesReducer) => state.additionalStyleConfigOptions,
  (state: DashboardStylesReducer) => state.globalStyleConfig,
  (state: DashboardStylesReducer) => state.dashboardTheme,
  (state: DashboardStylesReducer) => state.customStylesOverwriteConfig,
  (additionalStyleConfigOptions, globalStyleConfig, theme, customStylesOverwriteConfig) =>
    customStylesOverwriteConfig ??
    (theme ? additionalStyleConfigOptions[theme] : undefined) ??
    globalStyleConfig,
);

function getGlobalStyleConfig(state: DashboardStylesReducer) {
  return (
    state.customStylesOverwriteConfig ??
    (state.dashboardTheme ? state.additionalStyleConfigOptions[state.dashboardTheme] : undefined) ??
    state.globalStyleConfig
  );
}

function updateDataPanelColors(
  state: DashboardStylesReducer,
  dataPanelId: string,
  dataPanelTemplate: Pick<DataPanelResponse, '_rows' | '_schema'>,
) {
  const colorInfo = state.dataPanelIdToColorInfo[dataPanelId];
  if (!colorInfo) return;

  const globalStyleConfig = getGlobalStyleConfig(state);
  setColorCategoryData({
    colorCategoryTracker: state.colorCategoryTracker,
    globalStyleConfig,
    operationType: colorInfo.operationType,
    operationInstructions: colorInfo.operationInstructions,
    previewData: dataPanelTemplate._rows || [],
    schema: dataPanelTemplate._schema || [],
    dataPanelId: dataPanelId,
    dashboardCategoryColors: state.dashboardCategoryColors,
    shouldValuesShareColors: state.valuesShareColorsAcrossDashboard,
  });
  delete state.dataPanelIdToColorInfo[dataPanelId];
}

function saveDataPanelOperationData(
  state: DashboardStylesReducer,
  dataPanelId: string,
  operationType: OPERATION_TYPES,
  operationInstructions: VisualizeOperationInstructions,
) {
  if (!COLOR_SYNC_CHART_TYPES.has(operationType)) return;

  state.dataPanelIdToColorInfo[dataPanelId] = {
    operationType: operationType,
    operationInstructions: operationInstructions,
  };
}

function cloneAndSanitizeGlobalStyleConfig(
  globalStyleConfig: GlobalStyleConfig,
): GlobalStyleConfig {
  const sanitizedConfig = cloneDeep(globalStyleConfig);
  if (!sanitizedConfig.container.tooltip) {
    sanitizedConfig.container.tooltip = {
      backgroundColor: DEFAULT_TOOLTIP_BACKGROUND_COLOR,
    };
  }
  return sanitizedConfig;
}
