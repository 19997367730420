import {
  CustomerReportAgg,
  CustomerReportFilter,
  CustomerReportGroupBy,
  CustomerReportView,
} from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Timezones } from 'constants/dashboardConstants';
import {
  AGGREGATIONS_LIST,
  BOOLEAN,
  DATE,
  DATE_AGGREGATIONS_LIST,
  DATE_TYPES,
  FLOAT,
  NON_NUMBER_AGGREGATIONS_LIST,
  NUMBER_TYPES,
} from 'constants/dataConstants';
import {
  Aggregation,
  FilterValueDateType,
  FilterValueMultiSelectType,
  FilterValueNumberRangeType,
  FilterValueRelativeDateType,
  OPERATION_TYPES,
} from 'constants/types';
import { getAggregationType } from 'pages/ReportBuilder/utils/viewUtils';
import * as RD from 'remotedata';
import { EmbeddedReportBuilderReducerState } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportSchemaInfo } from 'reportBuilderContent/reducers/types';
import { BaseCol, ColumnConfigs } from 'types/columnTypes';
import { DatasetSchema } from 'types/datasets';
import {
  DATETIME_PART_AGGS,
  DATETIME_PIVOT_AGGS,
  DATE_PART_AGGS,
  DATE_PIVOT_AGGS,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES,
  DEFAULT_DATE_RANGES_SET,
  PivotAgg,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_OPTIONS_SET,
} from 'types/dateRangeTypes';
import {
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_NUMBER,
  FILTER_OPS_NUMBER_RANGE,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_STRING,
  FilterOperator,
  FilterOperatorWithSupport,
} from 'types/filterOperations';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { TIME_FORMATS, formatTime } from 'utils/localizationUtils';

import {
  getAggColDisplay,
  getAggColName,
  getGroupByDisplay,
  getGroupByName,
  getPivotSchemaAndConfigs,
} from './V2ColUtils';
import { getNextAggType, isAggOptionDisabled } from './aggUtils';
import { getTimezone } from './timezoneUtils';

export type DraggableColumnInfo = BaseCol & {
  displayName: string;
  isHidden: boolean;
  description?: string;
};

export const getDraggableColumns = (
  dataset: ReportBuilderDataset | undefined,
  columnOrder: string[] | undefined,
  hiddenColumns: string[] | undefined,
) => {
  if (!dataset?.schema || !columnOrder?.length) return [];

  const columns: DraggableColumnInfo[] = [];

  columnOrder.forEach((colName) => {
    const schemaCol = dataset.schema?.find((col) => col.name === colName);
    const colConfig = dataset.columnConfigs[colName];
    if (!schemaCol || !colConfig?.isVisible) return;

    columns.push({
      ...schemaCol,
      displayName: colConfig.name,
      isHidden: !!hiddenColumns?.find((col) => col === colName),
      description: colConfig.description,
    });
  });

  return columns;
};

export type FilterableColumn = {
  name: string;
  type: string;
  display: string;
  default: boolean;
  description: string | undefined;
  isPostFilter?: boolean;
};

/**
 * Get a list of columns from the original dataset that are visible and can be selected
 */
export const getDatasetColumns = (dataset: ReportBuilderDataset): FilterableColumn[] => {
  const filterableCols: FilterableColumn[] = [];
  dataset.schema?.forEach((col) => {
    const colConfig = dataset.columnConfigs[col.name];
    if (!colConfig?.isVisible) return;

    filterableCols.push({
      ...col,
      display: colConfig.name,
      default: colConfig.showDefaultFilter ?? false,
      description: colConfig.description,
    });
  });
  return filterableCols;
};

/**
 * Get a list of columns currently selected and can be filtered
 * Hidden columns are included because they can still be filtered
 */
export const getFilterableRawColumns = (columnOrder: string[], dataset: ReportBuilderDataset) => {
  const datasetCols = getDatasetColumns(dataset);
  const columnOrderSet = new Set(columnOrder);
  return datasetCols.filter((col) => columnOrderSet.has(col.name));
};

/**
 * Get a list of columns and aggs currently selected and can be filtered
 */
export const getFilterableColumns = (
  aggregations: CustomerReportAgg[] | undefined,
  columnOrder: string[],
  dataset: ReportBuilderDataset,
): FilterableColumn[] => {
  const filterableCols = getFilterableRawColumns(columnOrder, dataset);
  const filterableAggs = getFilterableAggs(aggregations || [], dataset);
  return [...filterableAggs, ...filterableCols];
};

/**
 * Get a list of aggregation columns from the original dataset that are visible and can be filtered
 */
const getFilterableAggs = (
  aggregations: CustomerReportAgg[],
  dataset: ReportBuilderDataset,
): FilterableColumn[] =>
  aggregations.map((agg) => {
    return {
      name: getAggColName(agg),
      display: getAggColDisplay(agg, dataset.columnConfigs),
      default: false,
      description: '',
      isPostFilter: true,
      type: getAggregationType(agg),
    };
  });

export type SortableColumn = { name: string; display: string; type: string };

export const getSortableColumns = (
  dataset: ReportBuilderDataset,
  view: CustomerReportView,
): SortableColumn[] => {
  if (
    (view.visualization && view.visualization !== OPERATION_TYPES.VISUALIZE_TABLE) ||
    view.columnGroupBys?.length
  )
    return [];

  const sortableColumns: SortableColumn[] = [];
  if (view.aggregations?.length || view.groupBys?.length) {
    view.aggregations?.forEach((agg) =>
      sortableColumns.push({
        name: getAggColName(agg),
        display: getAggColDisplay(agg, dataset.columnConfigs),
        type: FLOAT,
      }),
    );
    view.groupBys?.forEach((groupBy) =>
      sortableColumns.push({
        name: getGroupByName(groupBy),
        display: getGroupByDisplay(groupBy, dataset.columnConfigs),
        type: groupBy.column.type,
      }),
    );
  } else {
    dataset.schema?.forEach((col) => {
      const colConfig = dataset.columnConfigs[col.name];
      if (!colConfig?.isVisible) return;
      sortableColumns.push({ name: col.name, display: colConfig.name, type: col.type });
    });
  }
  return sortableColumns;
};

const FILTER_TIME_FORMAT = TIME_FORMATS['MM/DD/YYYY'];

const OPERATOR_NAME_MAP: Partial<Record<FilterOperator, string>> = {
  [FilterOperator.STRING_IS_IN]: FILTER_OPERATOR_TYPES_BY_ID.STRING_IS.name,
  [FilterOperator.STRING_IS_NOT_IN]: FILTER_OPERATOR_TYPES_BY_ID.STRING_IS_NOT.name,
  [FilterOperator.NUMBER_IS_IN]: FILTER_OPERATOR_TYPES_BY_ID.NUMBER_EQ.name,
  [FilterOperator.NUMBER_IS_NOT_IN]: FILTER_OPERATOR_TYPES_BY_ID.NUMBER_NEQ.name,
};

// Report Builder uses the same operators as Explore, but unlike Explore, "in" filters have the same UX as "equal" filters
// so we need to provide different display names to not confuse users
export const getOperatorName = (operator: FilterOperatorWithSupport): string => {
  return OPERATOR_NAME_MAP[operator.id] || operator.name;
};

export const getFilterClauseValueText = (clause: CustomerReportFilter): string => {
  const operator = FILTER_OPERATOR_TYPES_BY_ID[clause.filterOperation.id];
  if (FILTER_OPS_NO_VALUE.has(operator.id)) return operator.name;

  if (FILTER_OPS_NUMBER_RANGE.has(operator.id)) {
    const { min, max } = clause.filterValue as FilterValueNumberRangeType;
    if (min == null || max == null) return '';
    return `${operator.name} ${min} - ${max}`;
  }

  if (FILTER_OPS_MULTISELECT.has(operator.id)) {
    const multiSelectValue = clause.filterValue as FilterValueMultiSelectType;
    if (multiSelectValue.length === 0) return '';

    const operatorName = getOperatorName(operator);
    if (multiSelectValue.length === 1) return `${operatorName} ${multiSelectValue[0]}`;

    return `${operatorName} ${multiSelectValue.length} item${
      multiSelectValue.length !== 1 ? 's' : ''
    }`;
  }

  if (FILTER_OPS_NUMBER.has(operator.id)) {
    return `${operator.name} ${String(clause.filterValue)}`;
  }

  if (FILTER_OPS_STRING.has(operator.id)) {
    return `${operator.name} ${clause.filterValue}`;
  }

  if (FILTER_OPS_DATE_RANGE_PICKER.has(operator.id)) {
    const value = clause.filterValue as FilterValueDateType | DEFAULT_DATE_RANGES | undefined;
    if (!value) return '';
    if (typeof value === 'string') {
      if (!DEFAULT_DATE_RANGES_SET.has(value)) return '';
      return `${operator.name} ${DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[value] ?? value}`;
    }
    const { startDate, endDate } = (clause.filterValue as FilterValueDateType | undefined) ?? {};
    if (!startDate || !endDate) return '';

    const startString = formatTime(dateTimeFromISOString(startDate), FILTER_TIME_FORMAT);
    const endString = formatTime(dateTimeFromISOString(endDate), FILTER_TIME_FORMAT);
    return `${operator.name} ${startString} - ${endString}`;
  }

  if (FILTER_OPS_DATE_PICKER.has(operator.id)) {
    const value = clause.filterValue as FilterValueDateType | RELATIVE_DATE_OPTIONS | undefined;
    if (!value) return '';
    if (typeof value === 'string') {
      if (!RELATIVE_DATE_OPTIONS_SET.has(value)) return '';
      return `${operator.name} ${value}`;
    }
    if (!value.startDate) return '';
    const { startDate } = (clause.filterValue as FilterValueDateType | undefined) ?? {};
    if (!startDate) return '';

    const dateString = formatTime(dateTimeFromISOString(startDate), FILTER_TIME_FORMAT);
    return `${operator.name} ${dateString}`;
  }

  if (FILTER_OPS_RELATIVE_PICKER.has(operator.id)) {
    const { number, relativeTimeType } =
      (clause.filterValue as FilterValueRelativeDateType | undefined) ?? {};
    if (!number || !relativeTimeType) return '';

    const timeType = number === 1 ? relativeTimeType.id.slice(0, -1) : relativeTimeType.id;
    return `${operator.name} ${number} ${timeType.toLowerCase()}`;
  }

  return '';
};

export const getFilterDefaultOperation = (
  columnType: string,
  filterOperator?: FilterOperator,
): FilterOperator => {
  if (filterOperator) return filterOperator;

  if (NUMBER_TYPES.has(columnType)) return FilterOperator.NUMBER_IS_BETWEEN;
  if (columnType === BOOLEAN) return FilterOperator.BOOLEAN_IS_TRUE;
  if (DATE_TYPES.has(columnType)) return FilterOperator.DATE_IS_BETWEEN;
  return FilterOperator.STRING_IS_IN;
};

type RenderTableConfig = {
  schema: DatasetSchema;
  columnConfigs: ColumnConfigs;
  pivotColumns?: string[];
  groupByColumns?: string[];
};

export const getSchemaAndColConfigs = (
  dataset: ReportBuilderDataset | undefined,
  columnOrder: string[],
  hiddenColumns: string[],
  schemaInfo: ReportSchemaInfo | null,
): RenderTableConfig => {
  if (!dataset) return { schema: [], columnConfigs: {} };
  if (
    schemaInfo?.columnGroupBys.length ||
    schemaInfo?.groupBys.length ||
    schemaInfo?.aggs.length ||
    schemaInfo?.scatterPlotGrouping
  )
    return getPivotSchemaAndConfigs(
      dataset.columnConfigs,
      schemaInfo.groupBys,
      schemaInfo.columnGroupBys,
      schemaInfo.aggs,
      schemaInfo.scatterPlotGrouping,
      // TODO(zifanxiang): Look into if report builder should be passing through variables here
      /* variables= */ {},
    );

  return {
    columnConfigs: dataset.columnConfigs,
    schema: getCustomerReportSchema(dataset, columnOrder, hiddenColumns),
  };
};

const getCustomerReportSchema = (
  dataset: ReportBuilderDataset,
  selectedColumns: string[],
  hiddenColumns: string[],
): DatasetSchema => {
  if (!dataset.schema) return [];

  const hiddenSet = new Set(hiddenColumns);

  const schema: DatasetSchema = [];
  selectedColumns.forEach((col) => {
    if (hiddenSet.has(col)) return;

    const columnConfig = dataset.columnConfigs[col];
    if (!columnConfig?.isVisible) return;

    const schemaCol = dataset.schema?.find((c) => c.name === col);
    if (schemaCol) {
      schema.push({ name: col, friendly_name: columnConfig.name, type: schemaCol.type });
    }
  });
  return schema;
};

export const getReportName = (reportName: string) => {
  return reportName || 'Untitled Report';
};

export const getCurrentView = (
  views: CustomerReportView[] | undefined,
  currentView: string | null,
) => {
  return currentView ? views?.find((view) => view.id === currentView) : undefined;
};

export const getGroupByUniqueId = ({ bucket, column }: CustomerReportGroupBy): string => {
  if (!bucket) return column.name;
  return `${column.name}_${bucket}`;
};

// DATE_AGGS and DATE_TIME_AGGS used internally by getGroupByBucketAggs
const DATE_AGGS = [...DATE_PIVOT_AGGS, ...DATE_PART_AGGS];
const DATE_TIME_AGGS = [...DATETIME_PIVOT_AGGS, ...DATETIME_PART_AGGS];

export const getGroupByBucketAggs = (column: BaseCol, sourceType: string | null) => {
  if (!DATE_TYPES.has(column.type)) return [];

  const aggs = column.type === DATE ? DATE_AGGS : DATE_TIME_AGGS;
  return aggs.filter(
    (agg) =>
      agg.id !== PivotAgg.DATE_SMART && !isAggOptionDisabled(agg.id, sourceType || undefined),
  );
};

export const getNewGroupBy = (
  col: BaseCol,
  view: CustomerReportView,
  isColumnGroupBy: boolean,
  sourceType: string | null,
): CustomerReportGroupBy | null => {
  const groupBys = (view.groupBys ?? []).concat(view.columnGroupBys ?? []);
  if (DATE_TYPES.has(col.type)) {
    const usedBuckets = new Set<string>();
    groupBys.forEach(({ column, bucket }) => {
      if (column.name === col.name && bucket) usedBuckets.add(bucket);
    });

    if (!usedBuckets.has(PivotAgg.DATE_MONTH)) return { column: col, bucket: PivotAgg.DATE_MONTH };

    const aggs = getGroupByBucketAggs(col, sourceType);
    for (let i = 0; i < aggs.length; i++) {
      const datePivot = aggs[i].id;
      if (!usedBuckets.has(datePivot)) return { column: col, bucket: datePivot };
    }
  } else {
    const alreadyExists = (isColumnGroupBy ? view.columnGroupBys : view.groupBys)?.find(
      ({ column: { name } }) => name === col.name,
    );
    if (!alreadyExists) return { column: col };
  }

  return null;
};

export type BucketsByCol = Record<string, Set<PivotAgg>>;
type GroupByBucketOption = { value: PivotAgg; name: string };

export const getGroupByBucketOptions = (
  { column, bucket }: CustomerReportGroupBy,
  bucketsByCol: BucketsByCol,
  sourceType: string | null,
): GroupByBucketOption[] => {
  if (!bucket) return [];

  const selectedBuckets = bucketsByCol[column.name];
  const aggs = getGroupByBucketAggs(column, sourceType);

  return aggs.reduce<GroupByBucketOption[]>((acc, agg) => {
    if (!selectedBuckets.has(agg.id)) acc.push({ value: agg.id, name: agg.name });
    return acc;
  }, []);
};

export const getAggUniqueId = (agg: CustomerReportAgg): string => {
  return `${agg.column.name}_${agg.agg.id}`;
};

/*
 * @param col
 * @param aggregations - Existing view aggregations
 */
export const getNewAgg = (
  col: BaseCol,
  aggregations?: CustomerReportAgg[],
): CustomerReportAgg | null => {
  const curAggs: Set<Aggregation> = new Set();
  aggregations?.forEach(({ column, agg }) => {
    if (column.name === col.name) curAggs.add(agg.id);
  });
  const nextAgg = getNextAggType(col, curAggs);
  if (nextAgg) return { column: col, agg: nextAgg };

  return null;
};

export const getReportAggOptions = (
  { column }: CustomerReportAgg,
  aggsByCol: Record<string, Set<Aggregation>>,
  sourceType?: string,
) => {
  const aggs = aggsByCol[column.name];
  const options = NUMBER_TYPES.has(column.type)
    ? AGGREGATIONS_LIST
    : DATE_TYPES.has(column.type)
    ? DATE_AGGREGATIONS_LIST
    : NON_NUMBER_AGGREGATIONS_LIST;

  return options.reduce((acc, agg) => {
    if (!aggs.has(agg.id) && !isAggOptionDisabled(agg.id, sourceType))
      acc.push({ value: agg.id, name: agg.name });
    return acc;
  }, [] as { value: Aggregation; name: string }[]);
};

export const getReportBuilderTimezone = ({
  reportBuilder,
  timezone,
}: EmbeddedReportBuilderReducerState) => {
  // support deprecated behavior where you can pass in User Local Timezone via the embed
  if (timezone && timezone.trim().toLowerCase() === Timezones.USER_LOCAL_TIME) {
    return getTimezone(Timezones.USER_LOCAL_TIME);
  }

  const reportBuilderTimezone = RD.getOrDefault(reportBuilder, undefined)?.default_timezone;
  return getTimezone(reportBuilderTimezone || Timezones.UTC, timezone ?? undefined);
};
