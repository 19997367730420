import { combineReducers } from 'redux';

import { dashboardDataReducer } from 'reducers/dashboardDataReducer';
import { dashboardInteractionsReducer } from 'reducers/dashboardInteractionsReducer';
import { dashboardLayoutReducer } from 'reducers/dashboardLayoutReducer';
import { dashboardStylesReducer } from 'reducers/dashboardStylesReducer';
import drilldownsReducer from 'reducers/drilldownsReducer';
import { embedDashboardReducer } from 'reducers/embedDashboardReducer';
import { embeddedAuthReducer } from 'reducers/embeddedAuthReducer';
import { fidoReducer } from 'reducers/fidoReducer';
import { telemetryReducer } from 'telemetry/telemetryReducer';

const rootReducer = combineReducers({
  analytics: telemetryReducer,

  embedDashboard: embedDashboardReducer,
  dashboardData: dashboardDataReducer,
  dashboardLayout: dashboardLayoutReducer,
  dashboardInteractions: dashboardInteractionsReducer,
  dashboardStyles: dashboardStylesReducer,
  drilldowns: drilldownsReducer,
  fido: fidoReducer,
  embeddedAuth: embeddedAuthReducer,
});

export type EmbedReduxState = ReturnType<typeof rootReducer>;

export default rootReducer;
