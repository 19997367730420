import { isPlainObject } from 'utils/standard';

export type ValueType = string | number | object | null | undefined;

/**
 * Deeply (recursively) applies the keyMapping function to all the keys in the object
 * @param object
 * @param keyMappingFn The function to map onto the keys.
 * @returns An object with all keys mapped to the the result of the keyMappingFn
 */
export const deepMapKeys = (
  object: Record<string, ValueType>,
  keyMappingFn: (key: string) => string,
): Record<string, ValueType> => {
  return deepMapKeysAndValues(object, keyMappingFn, (key, value) => value);
};

/**
 * Deeply (recursively) applies the keyMapping function to all the keys in the object and the
 * valueMapping function to all the values in the object.
 * @param object
 * @param keyMappingFn The function to map onto the keys.
 * @param valueMappingFn The function to map onto the values
 * @returns An object with all keys and values mapped according to the results of the keyMappingFn
 *    and valueMappingFn functions.
 */
export const deepMapKeysAndValues = (
  object: Record<string, ValueType>,
  keyMappingFn: (key: string) => string,
  valueMappingFn: (key: string, value: ValueType) => ValueType,
): Record<string, ValueType> => {
  const mappedObject = {} as Record<string, ValueType>;

  for (const key in object) {
    const value = object[key];
    const newKey = keyMappingFn(key);

    if (isPlainObject(value)) {
      mappedObject[newKey] = valueMappingFn(
        key,
        deepMapKeysAndValues(
          value as Record<string, string | number | object>,
          keyMappingFn,
          valueMappingFn,
        ),
      );
    } else {
      mappedObject[newKey] = valueMappingFn(key, value);
    }
  }

  return mappedObject;
};

/**
 * Transforms an object into an array of objects each with a single key-value pair. E.g for the
 * object {a: 1, b: 2} it would return an array [{key: 'a', value: 1}, {key: 'b', value: 2}].
 */
export const transformObjectToSinglePropertyObjectArray = (
  object: Record<string, ValueType>,
): Array<{ key: string; value: ValueType }> => {
  return Object.keys(object).map((key) => ({ key, value: object[key] }));
};
