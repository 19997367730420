import {
  Computation,
  DataRequestParameters,
  ExportFormat,
  OpenAPI,
  QueryExecutionResponse,
  QueryExportResponse,
  QueryPreviewRequest,
  QueryResourceService,
  ViewRunRequest,
} from '@explo-tech/fido-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CustomerReportFilter, CustomerReportGroupBy } from 'actions/customerReportActions';
import { Dataset, FetchDashboardDatasetPreviewBody } from 'actions/datasetActions';
import { ExportSpreadsheetType } from 'actions/exportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { ACTION } from 'actions/types';
import { FilterOperationInstructions, OPERATION_TYPES, SortInfo } from 'constants/types';
import { DashboardStates, ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { AggColInfo, ColumnConfigs } from 'types/columnTypes';
import { DashboardVariableMap } from 'types/dashboardTypes';
import {
  AdHocOperationInstructions,
  SortInfo_DEPRECATED,
  VisualizeOperation,
} from 'types/dataPanelTemplate';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { convertSortInfoToList, getFilterInfo, getSortInfo } from 'utils/adHocUtils';
import { getReportBuilderTimezone } from 'utils/customerReportUtils';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import {
  generateExploreExportColumnOptions,
  generateReportBuilderExportColumnOptions,
  getQueryLimit,
} from 'utils/fido/fidoInstructionShimUtils';
import {
  generateComputations,
  generateReportBuilderComputations,
} from 'utils/fido/fidoInstructionShims';
import {
  ComputedViewWithIds,
  getDataSource,
  sanitizeFileNameForExports,
} from 'utils/fido/fidoUtils';
import { keyBy } from 'utils/standard';
import { FidoRequestFn, makeFidoThunkRequest } from 'utils/thunkUtils';

import { DashboardLayoutThunk } from '../dashboardLayoutThunks/types';
import { saveComputedView } from '../fidoThunks';

OpenAPI.BASE = process.env.REACT_APP_FIDO_URL ?? '';

/**
 * Fetches a preview of the provided ComputedView. This fetch uses the default data source of
 * the view's namespace to query data.
 */
export const fetchFidoViewPreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    view: Pick<ComputedViewWithIds, 'id' | 'namespaceId'>;
    body: QueryPreviewRequest;
    onSuccess?: (data: QueryExecutionResponse) => void;
  },
  { state: ReduxState }
>(ACTION.FETCH_FIDO_COMPUTED_VIEW_PREVIEW, async ({ body, onSuccess, view }, { getState }) => {
  const { fido, dashboardLayout } = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const dataSource = getDataSource(
    { namespaceId: view.namespaceId, ...dashboardLayout.requestInfo },
    fido,
  );

  requestFn = dataSource
    ? () => QueryResourceService.getQueryPreview(dataSource, view.namespaceId, body)
    : null;

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error loading preview for your query',
    onSuccess,
  );
});

/**
 * Report Builder Fido Thunks
 */
export const fetchFidoReportBuilderView = createAsyncThunk<
  QueryExecutionResponse,
  {
    id: string;
    dataset: ReportBuilderDataset;
    body: ViewRunRequest;
    customerId: number;
    timezone: string | undefined;
    primaryRequestId?: string | undefined;
  },
  { state: ReduxState }
>(ACTION.FETCH_FIDO_REPORT_BUILDER_VIEW, async ({ dataset, body, customerId }, { getState }) => {
  const { fido, parentSchemas, dataSource, customers } = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const { fido_id: viewId, namespace_id: namespaceId } = dataset;

  const customer = customers.cachedCustomers[customerId]?.customer;

  if (customer && viewId && namespaceId) {
    const dataSourceId = getDataSource({
      namespaceId,
      parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
      schemas: RD.getOrDefault(parentSchemas.usedParentSchemas, []),
      dataSources: RD.getOrDefault(dataSource.dataSources, []),
      type: 'app',
    });

    if (dataSourceId) {
      requestFn = () => QueryResourceService.runView(dataSourceId, namespaceId, viewId, body);
    }
  }

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error loading preview for your query',
  );
});

export const fetchFidoEmbedReportBuilderView = createAsyncThunk<
  QueryExecutionResponse,
  {
    id: string;
    dataset: ReportBuilderDataset;
    body: ViewRunRequest;
    timezone: string | undefined;
    primaryRequestId?: string | undefined;
  },
  { state: ReportBuilderReduxState }
>(ACTION.EMBED_FETCH_FIDO_REPORT_BUILDER_VIEW, async ({ dataset, body }, { getState }) => {
  const { fido } = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const { fido_id: viewId, namespace_id: namespaceId } = dataset;

  if (viewId && namespaceId) {
    const dataSourceId = getDataSource({ namespaceId: namespaceId, type: 'embedded' }, fido);

    if (dataSourceId) {
      requestFn = () => QueryResourceService.runView(dataSourceId, namespaceId, viewId, body);
    }
  }

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error loading preview for your query',
  );
});

export const fetchFidoReportBuilderQueryPreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    dataset: ReportBuilderDataset;
    body: QueryPreviewRequest;
    customerId: number;
    save?: boolean | undefined;
    timezone: string;
  },
  { state: ReduxState }
>(
  ACTION.FETCH_FIDO_REPORT_BUILDER_QUERY_PREVIEW,
  async ({ dataset, body, customerId, save }, { getState, dispatch }) => {
    const { fido, parentSchemas, dataSource, customers } = getState();

    let requestFn: FidoRequestFn<QueryExecutionResponse> = null;
    let onSuccess: ((response: QueryExecutionResponse) => void) | undefined = undefined;

    const selectedView = RD.getOrDefault(fido.computedViews, []).find(
      (view) => view.id == dataset.fido_id ?? '',
    );
    const customer = customers.cachedCustomers[customerId]?.customer;

    if (customer && selectedView) {
      const dataSourceId = getDataSource({
        namespaceId: selectedView.namespaceId,
        parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
        schemas: RD.getOrDefault(parentSchemas.usedParentSchemas, []),
        dataSources: RD.getOrDefault(dataSource.dataSources, []),
        type: 'app',
      });

      if (dataSourceId) {
        requestFn = () =>
          QueryResourceService.getQueryPreview(dataSourceId, selectedView.namespaceId, body);
        onSuccess = save
          ? (response) =>
              dispatch(
                saveComputedView({
                  ...selectedView,
                  query: body.query,
                  columnDefinitions: response.meta.schema.propertySchema,
                  permissions: dataset.permissions,
                }),
              )
          : undefined;
      }
    }

    return makeFidoThunkRequest(
      requestFn,
      fido.fidoToken ?? '',
      'Error loading preview for your query',
      onSuccess,
    );
  },
);

/**
 * Dashboard FIDO thunks
 */

/**
 * Fetches the data for the provided ComputedView. This fetch uses the current user's assigned
 * data source.
 */
export const fetchFidoViewData = createAsyncThunk<
  QueryExecutionResponse,
  {
    body: Pick<FetchDashboardDatasetPreviewBody, 'variables' | 'query_limit' | 'timezone'>;
    dataset: ResourceDataset;
  },
  { state: DashboardStates }
>(
  ACTION.FETCH_COMPUTED_VIEW_DATA,
  async ({ body: { variables, query_limit: queryLimit }, dataset }, { getState }) => {
    const { fido, dashboardLayout } = getState();

    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

    if (namespaceId && viewId) {
      const dataSource = getDataSource(
        { namespaceId: namespaceId, ...dashboardLayout.requestInfo },
        fido,
      );

      requestFn = dataSource
        ? () =>
            QueryResourceService.runView(dataSource, namespaceId, viewId, {
              queryContext: variables,
              dataRequestParameters: {
                pagingConfiguration: {
                  perPage: queryLimit,
                },
              },
              requestExecutionParameters: null,
              computation: null,
            })
        : null;
    }

    return makeFidoThunkRequest(
      requestFn,
      fido.fidoToken ?? '',
      'Error loading preview for your query',
    );
  },
);

/**
 * Fetches the data for the provided data panel. This fetch uses the current users's assigned data source
 */
export const fetchFidoComputationDataThunk =
  (
    dataPanel: DataPanel,
    datasets: Record<string, Dataset>,
    variables: DashboardVariableMap,
    adHocInstructions?: AdHocOperationInstructions,
    overriddenQueryLimit?: number,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const { fido, dashboardLayout, dashboardData } = getState();

    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    const { namespace_id: namespaceId, fido_id: viewId } =
      datasets[getDataPanelDatasetId(dataPanel)] ?? {};

    if (!namespaceId || !viewId) return;
    const dataSource = getDataSource({ namespaceId, ...dashboardLayout.requestInfo }, fido);

    if (!dataSource) return;

    const filterInfo = adHocInstructions
      ? adHocInstructions.filterInfo
      : getFilterInfo(dataPanel.visualize_op.operation_type, dataPanelData);
    const sortInfo = convertSortInfoToList(
      adHocInstructions ? adHocInstructions.sortInfo : getSortInfo(dataPanel, dataPanelData),
    );

    const pageNumber = adHocInstructions ? adHocInstructions.currentPage : undefined;

    const computations = generateComputations(
      dataPanel,
      { sortInfo, filterInfo },
      dashboardLayout.requestInfo.timezone,
    );

    if (!computations) return;

    const requestFn = () =>
      QueryResourceService.runView(dataSource, namespaceId, viewId, {
        queryContext: variables,
        dataRequestParameters: {
          pagingConfiguration: {
            page: Math.max((pageNumber ?? 1) - 1, 0),
            perPage:
              overriddenQueryLimit ??
              getQueryLimit(
                dataPanel.visualize_op,
                dashboardLayout.requestInfo.dataPanelMaxDataPoints,
              ),
          },
        },
        requestExecutionParameters: null,
        computation: computations.primaryComputation,
      });

    const primaryRequestId = dispatch(
      fetchFidoComputationData({
        requestFn,
        reducerArgs: {
          dataPanelId: dataPanel.id,
          filterInfo,
          sortInfo,
          pageNumber,
          isPrimaryRequest: true,
          visualizeOp: dataPanel.visualize_op,
          secondaryDataAgg: undefined,
          timezone: dashboardLayout.requestInfo.timezone,
          primaryRequestId: undefined,
        },
      }),
    ).requestId;

    (computations.secondaryComputations ?? []).forEach((computation) => {
      dispatch(
        fetchFidoComputationData({
          requestFn: () =>
            QueryResourceService.runView(dataSource, namespaceId, viewId, {
              queryContext: variables,
              dataRequestParameters: {
                pagingConfiguration: {
                  page: 0,
                  perPage:
                    overriddenQueryLimit ??
                    getQueryLimit(
                      dataPanel.visualize_op,
                      dashboardLayout.requestInfo.dataPanelMaxDataPoints,
                    ),
                },
              },
              requestExecutionParameters: null,
              computation,
            }),
          reducerArgs: {
            dataPanelId: dataPanel.id,
            filterInfo,
            sortInfo,
            pageNumber,
            isPrimaryRequest: false,
            visualizeOp: dataPanel.visualize_op,
            secondaryDataAgg: (computation as Computation).properties[0].targetPropertyId ?? '',
            timezone: dashboardLayout.requestInfo.timezone,
            primaryRequestId,
          },
        }),
      );
    });
  };

/**
 * Fetches the data for the provided data panel. This fetch uses the current users's assigned data source
 */
export const fetchFidoComputationData = createAsyncThunk<
  QueryExecutionResponse,
  {
    requestFn: FidoRequestFn<QueryExecutionResponse>;

    // used by the reducers
    reducerArgs: {
      dataPanelId: string;
      sortInfo: SortInfo_DEPRECATED[] | undefined;
      filterInfo: FilterOperationInstructions | undefined;
      pageNumber: number | undefined;
      isPrimaryRequest: boolean;
      secondaryDataAgg: string | undefined;
      timezone: string;
      visualizeOp: VisualizeOperation;
      primaryRequestId: string | undefined;
    };
  },
  { state: DashboardStates }
>(ACTION.FETCH_SUMMARIZED_VIEW_DATA, async ({ requestFn }, { getState }) => {
  const { fidoToken } = getState().fido;

  return makeFidoThunkRequest(requestFn, fidoToken ?? '', 'Error loading preview for your query');
});

export const downloadExploreComputationSpreadsheet = createAsyncThunk<
  QueryExportResponse,
  {
    dataPanel: DataPanel;
    dataset: ResourceDataset;
    adHocInstructions?: AdHocOperationInstructions;
    fileFormat: ExportSpreadsheetType;
    fileNameForExport: string;
    variables: DashboardVariableMap;
    emails: string[] | null;
  },
  { state: DashboardStates }
>(
  ACTION.FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET,
  async (
    { dataPanel, dataset, adHocInstructions, fileFormat, fileNameForExport, variables, emails },
    { getState, rejectWithValue },
  ) => {
    const { fido, dashboardLayout, dashboardData } = getState();

    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    if (!namespaceId || !viewId) return rejectWithValue('Unable to find namespace or view id');

    const dataSource = getDataSource({ namespaceId, ...dashboardLayout.requestInfo }, fido);

    if (!dataSource) return rejectWithValue('Unable to find data source');

    const visualizeOp = dataPanel.visualize_op;
    const filterInfo = adHocInstructions
      ? adHocInstructions.filterInfo
      : getFilterInfo(visualizeOp.operation_type, dataPanelData);
    const sortInfo = convertSortInfoToList(
      adHocInstructions ? adHocInstructions.sortInfo : getSortInfo(dataPanel, dataPanelData),
    );
    const computations = generateComputations(
      dataPanel,
      { sortInfo, filterInfo },
      dashboardLayout.requestInfo.timezone,
    );

    const exportOptions = visualizeOp.generalFormatOptions?.export;
    let exportFormat: ExportFormat;
    switch (fileFormat) {
      case 'csv':
        exportFormat = exportOptions?.csvFormat?.tsvEnabled ? ExportFormat.TSV : ExportFormat.CSV;
        break;
      case 'xlsx':
        exportFormat = ExportFormat.XLSX;
        break;
    }

    if (!computations) return rejectWithValue('Unable to generate computation');

    // TODO the only chart that requires two computations is KPI trend, do we allow exports for that?
    const exportComputation = computations.primaryComputation;

    // in getTransformedDataPanelForCsv, we apply some operations to changeSchemaList that cause it to
    // not include every column that could be selected, which causes csv downloads here to include extra
    // columns. This is because we use baseSchemaList to select our columns, but getTransformedDataPanelForCsv
    // doesn't transform that. It'd be a wide blast radius fix to try to fix that, so instead just doing
    // and extra filter pass here ensures that we're only selecting what the user knows about
    if (visualizeOp.operation_type == OPERATION_TYPES.VISUALIZE_TABLE) {
      const changeSchemaList = keyBy(
        visualizeOp.instructions.VISUALIZE_TABLE.changeSchemaList,
        'col',
      );
      exportComputation.properties = exportComputation.properties.filter((p) => {
        if (!('propertyId' in p) || !p.propertyId) {
          return p;
        }

        return changeSchemaList[p.propertyId]?.keepCol;
      });
    }

    const fileName = sanitizeFileNameForExports(fileNameForExport);

    return makeFidoThunkRequest(
      () =>
        QueryResourceService.exportView(dataSource, namespaceId, viewId, {
          queryContext: variables,
          emailConfiguration: emails
            ? {
                recipientEmails: emails,
                subject: `Requested Data Export: ${fileName} ${fileFormat}`,
                body: 'Attached below is the download you requested.',
              }
            : null,
          exportConfiguration: {
            fileName,
            exportFormat,
            columnDisplayOptions: generateExploreExportColumnOptions(
              exportComputation,
              dataPanel.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList,
            ),
          },
          computation: computations.primaryComputation,
        }),
      fido.fidoToken ?? '',
      'Error downloading your data panel',
    );
  },
);

export const FIDO_SUPPORTED_EXPORT_FORMATS = new Set<string>([
  ExportFormat.CSV,
  ExportFormat.XLSX,
  ExportFormat.TSV,
]);

export const downloadReportBuilderComputationSpreadsheet = createAsyncThunk<
  QueryExportResponse,
  {
    computationBody: {
      aggs: AggColInfo[];
      sort: SortInfo[];
      filters: CustomerReportFilter[];
      group_bys: CustomerReportGroupBy[] | undefined;
      col_group_bys: CustomerReportGroupBy[] | undefined;
      columns: string[];
      hidden_columns: string[];
      column_configs: ColumnConfigs;
    };
    dataset: ReportBuilderDataset;
    fileFormat: ExportSpreadsheetType;
    fileName: string;
    emails: string[] | null;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET_REPORT_BUILDER,
  async ({ computationBody, dataset, fileFormat, fileName }, { getState, rejectWithValue }) => {
    const { fido, embeddedReportBuilder } = getState();
    const {
      aggs,
      sort,
      filters,
      group_bys: groupBys,
      col_group_bys: colGroupBys,
      columns,
      hidden_columns: hiddenColumns,
      column_configs: columnConfigs,
    } = computationBody;

    const groupings = (groupBys ?? []).concat(colGroupBys ?? []).map((g) => ({
      column: g.column,
      bucket: g.bucket ? { id: g.bucket } : undefined,
    }));

    const computation = generateReportBuilderComputations(
      {
        aggs,
        sort,
        filters,
        group_bys: groupings,
        columns,
        hidden_columns: hiddenColumns,
      },
      dataset.customAggregations,
      getReportBuilderTimezone(embeddedReportBuilder),
    );

    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    if (!namespaceId || !viewId) return rejectWithValue('Unable to find namespace or view id');

    const dataSource = getDataSource({ namespaceId, type: 'embedded' }, fido);

    if (!dataSource) return rejectWithValue('Unable to find data source');
    let exportFormat: ExportFormat;
    switch (fileFormat) {
      case 'csv':
        exportFormat = ExportFormat.CSV;
        break;
      case 'xlsx':
        exportFormat = ExportFormat.XLSX;
        break;
    }

    return makeFidoThunkRequest(
      () =>
        QueryResourceService.exportView(dataSource, namespaceId, viewId, {
          queryContext: embeddedReportBuilder.variables,
          emailConfiguration: null,
          exportConfiguration: {
            fileName: sanitizeFileNameForExports(fileName),
            exportFormat,
            columnDisplayOptions: generateReportBuilderExportColumnOptions(
              computation.dataComputation,
              columnConfigs,
            ),
          },
          computation: computation.dataComputation,
        }),
      fido.fidoToken ?? '',
      'Error downloading your data panel',
    );
  },
);

/**
 * Fetches a table preview for a data source under a namespace.
 */
export const fetchFidoTablePreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    viewId: string;
    dataSourceId: string;
    namespaceId: string;
    body: DataRequestParameters;
    onSuccess?: (data: QueryExecutionResponse) => void;
  },
  { state: ReduxState }
>(
  ACTION.FETCH_FIDO_TABLE_VIEW_PREVIEW,
  async ({ viewId, dataSourceId, namespaceId, body, onSuccess }, { getState }) => {
    const state = getState();

    return makeFidoThunkRequest(
      () =>
        QueryResourceService.runView(dataSourceId, namespaceId, viewId, {
          dataRequestParameters: body,
          computation: null,
          queryContext: {},
          requestExecutionParameters: null,
        }),
      state.fido.fidoToken ?? '',
      'Error loading table preview',
      onSuccess,
    );
  },
);
