import { InputGroup as BP3InputGroup, IInputGroupProps, HTMLInputProps } from '@blueprintjs/core';
import cx from 'classnames';
import { ChangeEvent } from 'react';

import * as styles from './InputGroup.css';

type Props = IInputGroupProps &
  // Set onChange to undefined so its not passed in because it will be overwritten
  HTMLInputProps & { onInputChange?: (val: string) => void; onChange?: undefined };

export default function InputGroup({ className, onInputChange, ...otherProps }: Props) {
  return (
    <BP3InputGroup
      {...otherProps}
      className={cx(styles.inputGroupContainer, className)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onInputChange?.(e.target.value)}
    />
  );
}
