import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  CustomerReportGroupBy,
  CustomerReportAgg,
  BarFunnelChartView,
} from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { OPERATION_TYPES } from 'constants/types';
import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import {
  cloneRowsIfDate,
  customerReportToBarFunnelChartInstructions,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import { BarFunnel } from 'pages/dashboardPage/charts/BarFunnel';
import { getCurrentTheme } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';
import { DatasetColumn } from 'types/datasets';

type Props = {
  groupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  reportData: ReportData;
  schema: DatasetColumn[];
  columnConfigs?: ReportBuilderColConfigs;
  view: BarFunnelChartView;
};

export const ReportBarFunnelChart: FC<Props> = ({
  aggregations,
  reportData: { isLoading, rows },
  groupBys,
  schema,
  columnConfigs,
  view,
}) => {
  const { variables, globalStyleConfig } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: getCurrentTheme(state.embeddedReportBuilder),
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () =>
      customerReportToBarFunnelChartInstructions(
        aggregations,
        groupBys,
        columnConfigs,
        view.barFunnelChart,
      ),
    [aggregations, groupBys, columnConfigs, view.barFunnelChart],
  );

  // Bar FunnelChart mutates previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const previewData = useMemo(() => cloneRowsIfDate(groupBys, rows), [groupBys, rows]);

  const missingText = useMemo(
    () => [...(!aggregations?.length ? ['Amount'] : []), ...(!groupBys?.length ? ['Stages'] : [])],
    [aggregations?.length, groupBys?.length],
  );

  if (missingText.length) {
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2}>
        Missing {missingText.join(' and ')}
      </InvalidData>
    );
  }

  return (
    <BarFunnel
      dataPanelId=""
      generalFormatOptions={undefined}
      globalStyleConfig={globalStyleConfig}
      instructions={instructions}
      loading={isLoading}
      previewData={previewData}
      schema={schema}
      variables={variables}
    />
  );
};
