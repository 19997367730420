import { VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN } from 'constants/dataConstants';
import {
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';

export class TwoDimensionalDataPanelUtils {
  static isUnderlyingDataEnabled(
    generalFormatOptions: VisualizeOperationGeneralFormatOptions | undefined,
  ): boolean {
    return generalFormatOptions?.enableRawDataDrilldown ?? false;
  }

  static isCategorySelectEnabled(
    instructions: V2TwoDimensionChartInstructions | undefined,
    operationType: OPERATION_TYPES,
  ): boolean {
    return (
      (instructions?.drilldown?.categorySelectEnabled ?? false) &&
      VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(operationType)
    );
  }

  static isCustomMenuEnabled(
    generalFormatOptions: VisualizeOperationGeneralFormatOptions | undefined,
  ): boolean {
    return generalFormatOptions?.customMenu?.enabled ?? false;
  }

  static doesChartHaveClickEvents(
    instructions: V2TwoDimensionChartInstructions | undefined,
    generalFormatOptions: VisualizeOperationGeneralFormatOptions | undefined,
    drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>,
    operationType: OPERATION_TYPES,
  ): boolean {
    return (
      TwoDimensionalDataPanelUtils.isUnderlyingDataEnabled(generalFormatOptions) ||
      TwoDimensionalDataPanelUtils.isCategorySelectEnabled(instructions, operationType) ||
      Object.keys(drilldownEntryPoints ?? {}).length > 0
    );
  }
}
